import React from "react";
import useCookie from "@lib/use-cookie";

const LogoutButton = () => {
  const [cookie, updateCookie] = useCookie("userToken", null);
  const [sessionCookie, updateSessionCookie] = useCookie(
    "_equity_eats_session",
    null,
  );

  const handleLogout = () => {
    updateCookie("", -365);
    updateSessionCookie("", -365);
    location.reload();
  };

  return (
    <button
      className="login-ca__button login-ca__continue-button actionable"
      type="button"
      onClick={handleLogout}
    >
      Log out
    </button>
  );
};

export default LogoutButton;
