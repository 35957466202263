/** @jsx jsx */
import { jsx } from "@emotion/react";
import WelcomeScreen from "./WelcomeScreen";
import { isMobileDevice } from "../2fa-user-flow";
import NewLoginCreateAccountUserFlow from "../2fa-user-flow/NewLoginCreateAccountUserFlow";
import PhoneMenu from "./PhoneMenu";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { debounce } from "../2fa-user-flow/CarouselSectionMobile";

export default function SignupAndSignInSection({
  setStep,
  setUser,
  initialValues,
  step,
  setNearbyPrograms,
  nearbyPrograms,
  allActivePrograms,
  setAllActivePrograms,
  cloudinaryPath,
}) {
  const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    const isScrollbarClick =
      event.clientX >= document.documentElement.clientWidth ||
      event.clientY >= document.documentElement.clientHeight;

    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      !isScrollbarClick
    ) {
      event.preventDefault();
      event.stopPropagation();
      setStep(1);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="signup-and-signin">
      <div className="left-section">
        <WelcomeScreen
          setNearbyPrograms={setNearbyPrograms}
          nearbyPrograms={nearbyPrograms}
          allActivePrograms={allActivePrograms}
          setAllActivePrograms={setAllActivePrograms}
          cloudinaryPath={cloudinaryPath}
          locationsCount={initialValues.locationsCount}
        />
      </div>
      <div className="right-section" ref={sidebarRef}>
        <NewLoginCreateAccountUserFlow
          country={"US"}
          setUser={setUser}
          siteArea={"ambassador_flow"}
          setStep={setStep}
          alwaysDesktop={true}
        />
        {isMobileDevice() ? <PhoneMenu step={step} setStep={setStep} /> : null}
      </div>
    </div>
  );
}

SignupAndSignInSection.propTypes = {
  setStep: PropTypes.func,
  setUser: PropTypes.func,
  initialValues: PropTypes.object,
  step: PropTypes.number,
  setNearbyPrograms: PropTypes.func,
  nearbyPrograms: PropTypes.array,
  allActivePrograms: PropTypes.array,
  setAllActivePrograms: PropTypes.func,
  cloudinaryPath: PropTypes.string,
};
