/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, useEffect, useRef, useCallback, useContext } from "react";
import ListItem from "./ListItem";
import { ListContext } from "./";
import { getCookie } from "../../../lib/helpers";
import PropTypes from "prop-types";

// Helper function to handle API response data
const handleProgramData = (data, currentPage, setUserList, setHasMore) => {
  if (!data || !data.ambassador_programs || !data.ambassador_programs.length) {
    setHasMore(false);
    return;
  }

  const programs = data.ambassador_programs;

  // Update user list based on the current page
  const newPrograms = currentPage === 1 ? programs.slice(4) : programs;
  setUserList((prev) => [...prev, ...newPrograms]);

  // Set the hasMore state
  setHasMore(programs.length > 0);
};

const userToken = getCookie("userToken") || null;

function Scroll({
  children,
  url = "",
  showRestaurantDetails,
  parentClassName,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { userList, setUserList, currentPage, setCurrentPage } =
    useContext(ListContext);
  const [firstCall, setFirstCall] = useState(true);

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let headers = {};
      if (userToken) {
        headers = new Headers({ Authorization: userToken });
      }
      try {
        const count = 20;
        let receivedUrl = url;
        if (receivedUrl) {
          receivedUrl = receivedUrl.replace("|count|", count);
          receivedUrl = receivedUrl.replace("|page_number|", currentPage);
        }
        await fetch(receivedUrl, { headers })
          .then((res) => res.json())
          .then((data) =>
            handleProgramData(data, currentPage, setUserList, setHasMore),
          )
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
        setHasMore(false);
      } finally {
        setIsLoading(false);
        setFirstCall(false);
      }
    };

    if ((currentPage > 1 && !firstCall) || currentPage < 2) fetchData();
    setFirstCall(false);
  }, [currentPage]);
  return (
    <div className={`list-container ${parentClassName}`}>
      {userList.map((item, index) => (
        <div
          key={`${item?.offer?.id}-${item.id}`}
          ref={index === userList.length - 1 ? lastItemRef : null}
          className="list-item"
          onClick={(e) => showRestaurantDetails(e, item)}
          role="button" // Add appropriate ARIA role
          tabIndex={0} // Make it focusable
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent default for spacebar
              showRestaurantDetails(e, item); // Trigger click event on Enter or Space
            }
          }} // Handle keyboard events
        >
          <ListItem item={item} />
        </div>
      ))}
      {isLoading && <div className="loading-indicator">Loading...</div>}
    </div>
  );
}

export default Scroll;

Scroll.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  showRestaurantDetails: PropTypes.func,
  parentClassName: PropTypes.string,
};
