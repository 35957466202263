import React from "react";
import { useFormikContext } from "formik";

const VerifyCode = () => {
  const { setFieldValue, errors, touched, values, submitForm, isSubmitting } =
    useFormikContext();

  const [lastCode, setLastCode] = React.useState("");

  React.useEffect(() => {
    handleIndInputs();
  }, []);

  React.useEffect(() => {
    if (values.sixDigitCode.length === 6 && values.sixDigitCode !== lastCode) {
      setLastCode(values.sixDigitCode);
    }
  }, [values.sixDigitCode]);

  React.useEffect(() => {
    if (lastCode.length === 6 && values.sixDigitCode.length === 6) {
      submitForm();
    }
  }, [lastCode]);

  React.useEffect(() => {
    if (!isSubmitting && values.sixDigitCode.length > 0) {
      const inputs = document.querySelectorAll("#otp > *");
      inputs.forEach((input) => {
        input.value = "";
      });
      setFieldValue("sixDigitCode", "");
      inputs[0].focus();
    }
  }, [isSubmitting]);

  const handlePaste = (event) => {
    event.preventDefault();
    event.clipboardData.items[0].getAsString((text) => {
      text = text.substring(0, 6);
      let checkNum = Number(text);
      if (isNaN(checkNum)) {
        return;
      }
      const inputs = document.querySelectorAll("#otp input");
      for (let i = 0; i < inputs.length; i++) {
        if (typeof text[i] !== "undefined") {
          inputs[i].value = text[i];
        }
      }
      if (text.length < 6) {
        inputs[text.length - 1].select();
      }
      setFieldValue("sixDigitCode", "");
      setFieldValue("sixDigitCode", text);
    });
  };

  const handleAutofill = (e) => {
    e.preventDefault();
  };

  const popuNext = (el, data) => {
    const inputs = document.querySelectorAll("#otp > *");
    el.value = data[0]; // Apply first item to first input
    data = data.substring(1); // remove the first char.
    if (el.nextElementSibling && data.length) {
      popuNext(el.nextElementSibling, data);
    } else {
      let names = [];
      for (let inp of inputs) {
        names.push(inp.value);
      }
      setFieldValue("sixDigitCode", names.join(""));
    }
  };

  const handleInput = (e) => {
    let data = e.data || e.target.value;
    if (!data) return;
    if (data.length <= 1) {
      return;
    } else {
      popuNext(e.target, data);
    }
  };

  const handleIndInputs = () => {
    const inputs = document.querySelectorAll("#otp > *");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      });
    }

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("keyup", function (event) {
        if (
          (event.keyCode === 86 && event.metaKey === true) ||
          event.key === "Enter"
        ) {
          return;
        }
        if (event.key === "ArrowRight") {
          inputs[i].nextElementSibling.select();
          return;
        }
        if (event.key === "ArrowLeft") {
          inputs[i].previousElementSibling.select();
          return;
        }
        if (event.key === "Backspace") {
          inputs[i].value = "";
          if (i !== 0) inputs[i - 1].focus();
        } else if (event.keyCode > 47 && event.keyCode < 58) {
          inputs[i].value = event.key;
          if (inputs[i].nextElementSibling) {
            inputs[i].nextElementSibling.select();
          }
        } else if (event.keyCode > 64 && event.keyCode < 91) {
          event.preventDefault();
          return;
        } else if (inputs[i].nextElementSibling) {
          inputs[i].nextElementSibling.select();
        }
        let names = [];
        for (let inp of inputs) {
          names.push(inp.value);
        }
        setFieldValue("sixDigitCode", names.join(""));
      });
    }
    const thisInput = inputs[0];
    thisInput.select();
  };

  return (
    <div>
      <div
        id="otp"
        className={`login-ca__code-container ${
          errors.sixDigitCode ? "error-outline" : ""
        }`}
        onPaste={(e) => handlePaste(e)}
      >
        <input
          name="code1"
          inputMode="numeric"
          type="numeric"
          pattern="[0-9]*"
          autoComplete="one-time-code"
          maxLength="6"
          id="first-input"
          onChange={(e) => handleAutofill(e)}
          onInput={(e) => handleInput(e)}
        />
        <input
          name="code2"
          inputMode="numeric"
          type="numeric"
          pattern="[0-9]*"
          maxLength="1"
        />
        <input
          name="code3"
          inputMode="numeric"
          type="numeric"
          pattern="[0-9]*"
          maxLength="1"
        />
        <input
          name="code4"
          inputMode="numeric"
          type="numeric"
          pattern="[0-9]*"
          maxLength="1"
        />
        <input
          name="code5"
          inputMode="numeric"
          type="numeric"
          pattern="[0-9]*"
          maxLength="1"
        />
        <input
          name="code6"
          inputMode="numeric"
          type="numeric"
          pattern="[0-9]*"
          maxLength="1"
        />
      </div>
      {errors.sixDigitCode && touched.sixDigitCode ? (
        <div className="login-ca__error">{errors.sixDigitCode}</div>
      ) : null}
    </div>
  );
};

export default VerifyCode;
