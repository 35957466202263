import { createSlice } from "@reduxjs/toolkit";

//Initial state

const initialState = {
  gift: false,
  pageStatus: "checkout",
  emailMeGift: false,
  bonusMeGift: true,
  ref: null,
  giftRef: null,
  purchaseResponse: null,
  utm: null,
  ikPass: false,
  ikPassSingle: false,
  subscription: false,
  curSubscriptionTier: {},
  showToast: false,
};

// Create reducers/actions

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    giftBoolean: (state, action) => {
      state.gift = action.payload;
    },
    setPageStatus: (state, action) => {
      state.pageStatus = action.payload;
    },
    setShowToast: (state, action) => {
      state.showToast = action.payload;
    },
    changeGiftMe: (state) => {
      state.emailMeGift = !state.emailMeGift;
    },
    changeBonusMe: (state, action) => {
      state.bonusMeGift = action.payload;
    },
    setRef: (state, action) => {
      state.ref = action.payload;
    },
    setGiftRef: (state, action) => {
      state.giftRef = action.payload;
    },
    setPurchaseResponse: (state, action) => {
      state.purchaseResponse = action.payload;
    },
    setUTMData: (state, action) => {
      state.utm = action.payload;
    },
    setIkPass: (state, action) => {
      state.ikPass = action.payload;
    },
    setIkPassSingle: (state, action) => {
      state.ikPassSingle = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setCurSubscriptionTier: (state, action) => {
      state.curSubscriptionTier = action.payload;
    },
  },
});

// Export actions

export const {
  giftBoolean,
  setPageStatus,
  changeGiftMe,
  changeBonusMe,
  setRef,
  setGiftRef,
  setPurchaseResponse,
  setUTMData,
  setIkPass,
  setIkPassSingle,
  setSubscription,
  setCurSubscriptionTier,
  setShowToast,
} = navSlice.actions;

export default navSlice.reducer;
