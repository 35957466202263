import React from "react";
import noteIcon from "../../../assets/images/icons/sticky-note.svg";
import emailedIcon from "../../../assets/images/icons/emailed_solid.svg";

const ActivityAttachment = ({ attachment }) => {
  const size = attachment.filesize.toFixed(1);
  return (
    <div className="activity-attachment">
      <img
        className="activity-attachment-image"
        src={attachment.url}
        onClick={() => {
          window.open(attachment.url, "_blank");
        }}
      />
      <div>
        {attachment.filename}
        <span className="activity-date">
          &nbsp;{" • "}&nbsp;{size === "0.0" ? "0.1" : size} MB
        </span>
      </div>
    </div>
  );
};

const ActivityRow = ({
  activity,
  index,
  getDateTime,
  getOrdinal,
  brandOwners,
}) => {
  return (
    <li className="activity-row">
      <div className="activity-image">
        {activity?.user_name
          ?.split(" ")
          .map((word) => {
            return word[0];
          })
          .join("")}
      </div>
      <div className="activity-action">
        <div>
          <span className="activity-sender">
            {activity?.user_name}

            {activity.is_internal === true && (
              <>
                <span className="activity-date">&nbsp;{" • "}&nbsp;</span>
                <span className="activity-internal">Note to self</span>
              </>
            )}
          </span>
          <span className="activity-date">
            &nbsp;{" • "}&nbsp;
            {getDateTime(activity.created_at)}
          </span>
        </div>
        {!!activity?.message > 0 && (
          <div className="activity-note">{activity.message}</div>
        )}
        {activity.preview_sent === true && (
          <div className="activity-emailed-button">
            <img src={emailedIcon} />
            <span>
              Preview emailed to{" "}
              {activity.is_internal === false
                ? brandOwners?.join(", ")
                : activity.user_name}
            </span>
          </div>
        )}
        {activity.activity_type === "partner_approval" && (
          <div className="activity-approved-button">
            <span>Approved</span>
          </div>
        )}

        <div
          className="activity-attachments-container"
          style={{ display: activity?.images?.length > 0 ? "flex" : "none" }}
        >
          {activity?.images?.map((attachment, index) => {
            return <ActivityAttachment attachment={attachment} key={index} />;
          })}
        </div>
      </div>
    </li>
  );
};

export default ActivityRow;
