import { createSlice } from "@reduxjs/toolkit";

const regex = /<img.*?src=['"](.*?)['"]/;

const initialState = {
  presenter: {},
};

// Create reducers/actions

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.presenter = action.payload;
      // state.img = regex.exec(action.payload.main_image_url)[1];
    },
  },
});

// Export actions

export const { setCompany } = companySlice.actions;

export default companySlice.reducer;
