import React from "react";
import PhoneEntry from "./PhoneEntry";
import VerifyCode from "./VerifyCode";
import EmailEntry from "./EmailEntry";
import CreateAccount from "./CreateAccount";
import SignIn from "./SignIn";
import VerifyYourEmail from "./VerifyYourEmail";
import VerifyAnotherWay from "./VerifyAnotherWay";
import { Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import LoginCePageWrapper from "./LoginCePageWrapper";
import { object, string, boolean } from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import validator from "validator";
import { dnsCheck } from "../../helpers/dns-check";
import { handlePost, handleGet } from "./utilities";
import useCookie from "@lib/use-cookie";
import withEventsLogger from "@/events/withEventsLogger";
import useDeviceId from "@/events/useDeviceId";
import useSessionId from "@/events/useSessionId";

//which view are we currently on in the login/signup process?
export const PageState = {
  start: 0,
  sms_opt_in_prompt: 1,
  verify_sna: 2,
  verify_sms_otp: 2,
  email_prompt: 3,
  email_re_prompt: 3,
  auth_prompt: 5,
  signup_prompt: 4,
  verify_email: 7,
  login_success: 8,
};

const NewLoginCreateAccount = ({
  country,
  isBslCheckout,
  setUser,
  siteArea,
  logEvents,
}) => {
  const formikRef = React.useRef();

  const isPartnerPortal =
    siteArea === "partner_admin" || siteArea === "partner_invite";

  const inviteToken = window.location.hash.replace("#", "");

  const initialValues = {
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    sixDigitCode: 0,
    agreeToComm: false,
    agreeToTerms: false,
    countryCode: country ?? "",
  };

  const deviceId = useDeviceId();
  const sessionId = useSessionId();
  const [curPage, setCurPage] = React.useState(0);
  const [backArr, setBackArr] = React.useState([0]);
  const [shouldShowToast, setShouldShowToast] = React.useState(false);
  const [phoneToken, setPhoneToken] = React.useState("");
  const [emailToken, setEmailToken] = React.useState("");
  const [authToken, setAuthToken] = React.useState("");
  const [error, setError] = React.useState("");
  const [verifyAction, setVerifyAction] = React.useState("");
  const [cookie, setCookie] = useCookie("userToken", null);
  const [phoneToText, setPhoneToText] = React.useState("");
  const [prevPhoneEntry, setPrevPhoneEntry] = React.useState("");
  const [prevValue, setPrevValue] = React.useState("");

  const userSchema = object({
    phone: string()
      .required("Phone number is required.")
      .test({
        name: "valid",
        exclusive: false,
        message: "Invalid phone number.",
        test: function (value) {
          return (
            (curPage > 1 && curPage !== 4) ||
            isValidPhoneNumber("+".concat(value.replace(/\D/g, "")), country) ||
            window.location.host.includes("inkind-staging.com")
          );
        },
      }),
    email: string().test({
      name: "valid",
      exclusive: false,
      message: "Invalid email address.",
      test: async function (value) {
        return (
          curPage < 3 ||
          curPage > 4 ||
          (!!value?.length > 0 &&
            validator.isEmail(value) &&
            (await dnsCheck(value)))
        );
      },
    }),
    agreeToComm: boolean().test({
      name: "valid",
      exclusive: false,
      message: "You must agree to the terms to continue.",
      test: function (value) {
        return curPage !== 1 || !!value;
      },
    }),
    agreeToTerms: boolean().test({
      name: "valid",
      exclusive: false,
      message: "You must agree to the terms to continue.",
      test: function (value) {
        return curPage !== 4 || !!value;
      },
    }),
    sixDigitCode: string().test({
      name: "valid",
      exclusive: false,
      message: "Code must be six digits long.",
      test: function (value) {
        return curPage !== 2 || value?.length === 6;
      },
    }),
    password: string().test({
      name: "valid",
      exclusive: false,
      message: "Password is required.",
      test: function (value) {
        return curPage < 4 || curPage > 5 || value?.length > 0;
      },
    }),
    confirmPassword: string().test({
      name: "valid",
      exclusive: false,
      message: "Your password and password confirmation don't match.",
      test: function (value) {
        return (
          curPage !== 4 ||
          (value?.length > 0 && formikRef.current.values.password === value)
        );
      },
    }),
    firstName: string().test({
      name: "valid",
      exclusive: false,
      message: "First name is required.",
      test: function (value) {
        return curPage !== 4 || value?.length > 0;
      },
    }),
    lastName: string().test({
      name: "valid",
      exclusive: false,
      message: "Last name is required.",
      test: function (value) {
        return curPage !== 4 || value?.length > 0;
      },
    }),
  });

  const handleBack = () => {
    const newBackArr = [...backArr];
    newBackArr.pop();
    setBackArr(newBackArr);
    setCurPage(newBackArr[newBackArr.length - 1]);
  };

  const appendBackArr = (page) => {
    if (!page) {
      return;
    }
    if (page !== backArr[backArr.length - 1] && page !== 1) {
      const newBackArr = [...backArr];
      newBackArr.push(page);
      setBackArr(newBackArr);
    }
  };

  const getUserData = async () => {
    const headers = {
      Authorization: "Bearer ".concat(cookie),
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    const result = await handleGet("/api/v5/current_user", headers, setError);
    formikRef.current.setSubmitting(false);
    if (result) {
      setUser(result.user, shouldShowToast);
    }
  };

  const handleResult = (result) => {
    if (result.invite_email) {
      formikRef.current.setFieldValue("email", result.invite_email);
    }
    if (result.auth_token) {
      setAuthToken(result.auth_token);
    }
    if (result.email_verified === false) {
      setShouldShowToast(true);
    } else if (result.email_verified === true) {
      setShouldShowToast(false);
    }
    if (result.phone_number) {
      setPhoneToText(result.phone_number);
    }
    if (result.verify_action === "login_success") {
      setCookie(result.auth_token, 365);
    }
    if (result?.phone_token) {
      setPhoneToken(result.phone_token);
    }
    if (result?.email_token) {
      setEmailToken(result.email_token);
    }
    setVerifyAction(result?.verify_action);
    setCurPage(PageState[result?.verify_action]);
    if (result?.verify_action === "email_re_prompt") {
      setError("That email looks odd. Please verify that it's correct.");
    }
    appendBackArr(PageState[result?.verify_action]);
  };

  const logInAdminPartnerUser = async () => {
    let body = {
      auth_token: cookie,
      page: siteArea,
      email_verified: !shouldShowToast,
    };
    let bodyJson = JSON.stringify(body);
    const header = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    const result = await handlePost(
      "/api/v5/verifications/session",
      bodyJson,
      setError,
      header,
    );
    if (result) {
      let redirectLink;
      if (siteArea === "admin" && result["permissions"] === "admin") {
        redirectLink = result["return_to_path"] || "/admin/projects";
      } else if (siteArea === "admin") {
        redirectLink = result["return_to_path"] || "/admin/users";
      } else {
        redirectLink = "/partner_portal/subscriber_reports";
      }
      window.location.href = redirectLink;
    } else {
      formikRef.current.setSubmitting(false);
      setCurPage(8);
    }
  };

  const handleResend = async () => {
    let body = {
      email_token: emailToken,
    };
    let bodyJson = JSON.stringify(body);
    const header = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    const result = await handlePost(
      "/api/v5/verifications/email/resend",
      bodyJson,
      setError,
      header,
    );
    return result;
  };

  const handleAmplitude = (eventName, eventProperties = {}) => {
    logEvents([
      {
        session_id: sessionId,
        event_name: eventName,
        device_id: deviceId,
        event_properties: {
          ...eventProperties,
        },
      },
    ]);
  };

  const handleSubmit = async (values) => {
    let path;
    let body = {};
    if (siteArea === "partner_invite") {
      body.invite_token = inviteToken;
    }
    switch (true) {
      case curPage === 0:
        path = "/api/v5/verifications/phone";
        body.phone_number = "+".concat(values.phone.replace(/\D/g, ""));
        body.cc = values.countryCode;
        body.auth_source = siteArea;
        if (siteArea === "partner_invite") {
          body.opt_in_sms_source = "partner_invite";
        }
        handleAmplitude("enter_phone_number_to_login", {
          phone_number: values.phone,
        });
        setPrevPhoneEntry(body.phone_number);
        break;
      case curPage === 1:
        path = "/api/v5/verifications/phone";
        body.phone_number = "+".concat(values.phone.replace(/\D/g, ""));
        body.cc = values.countryCode;
        body.opt_in_sms = values.agreeToComm;
        body.verify_action = verifyAction;
        body.auth_source = siteArea;
        if (siteArea === "partner_invite") {
          body.opt_in_sms_source = "partner_invite";
        }
        handleAmplitude("enter_phone_number_to_login", {
          phone_number: values.phone,
        });
        break;
      case curPage === 2:
        path = "/api/v5/verifications/phone/verify";
        body.verification = "otp_code";
        body.verification_code = values.sixDigitCode;
        body.phone_token = phoneToken;
        body.verify_action = verifyAction;
        handleAmplitude("enter_2fa_code", {
          phone_number: values.phone,
        });
        break;
      case curPage === 3:
        path = "/api/v5/verifications/email";
        body.email = values.email;
        body.phone_token = phoneToken;
        body.verify_action = verifyAction;
        handleAmplitude("enter_login_email", {
          email: values.email,
        });
        break;
      case curPage === 4:
        path = "/api/v5/verifications/signup";
        body.email_token = emailToken;
        body.phone_token = phoneToken;
        body.first_name = values.firstName;
        body.last_name = values.lastName;
        body.password = values.password;
        body.password_confirmation = values.confirmPassword;
        body.tos_checkbox = values.agreeToTerms;
        body.tos_agree_source = "";
        handleAmplitude("complete_amount_creation", { email: values.email });
        break;
      case curPage === 5:
        path = "/api/v5/verifications/auth";
        body.email_token = emailToken;
        body.phone_token = phoneToken;
        body.password = values.password;
        handleAmplitude("enter_login_password", {
          email: values.email,
        });
        break;
      case curPage === 7:
        if (authToken?.length > 0) {
          setCookie(authToken, 365);
          return;
        } else {
          path = "/api/v5/verifications/email/verify";
          body.email_token = emailToken;
        }
        break;
      default:
        break;
    }
    let bodyJson = JSON.stringify(body);
    const header = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    const result = await handlePost(path, bodyJson, setError, header);
    if (!result) {
      formikRef.current.setSubmitting(false);
      return;
    }
    handleResult(result);
    if (formikRef.current.values?.sixDigitCode?.length === 6) {
      formikRef.current.setFieldValue("sixDigitCode", "");
    }
    formikRef.current.setSubmitting(false);
  };

  const handleLogin = () => {
    if (cookie && setUser) {
      formikRef.current.setSubmitting(true);
      getUserData();
    } else if (
      cookie &&
      (siteArea === "admin" || siteArea === "partner_portal")
    ) {
      formikRef.current.setSubmitting(true);
      logInAdminPartnerUser();
    }
  };

  const handlePageChange = () => {
    formikRef.current.setErrors({});
    if (curPage !== 8) {
      setError("");
    }
    if (curPage === 6) {
      setVerifyAction("verify_sms_otp");
      appendBackArr(6);
    }
    if (curPage === 1) {
      document.querySelector(".login-ca__form input[type='checkbox']").focus();
    } else {
      document.querySelector(".login-ca__form input")?.focus();
    }
  };

  React.useEffect(() => {
    handleLogin();
  }, [cookie]);

  React.useEffect(() => {
    handlePageChange();
  }, [curPage]);

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      validationSchema={userSchema}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form className="login-ca__form" onSubmit={(e) => handleSubmit(e)}>
          <AnimatePresence>
            {isSubmitting && (
              <motion.div
                key="loading"
                className="login-ca__loader-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <div className="login-ca__loader"></div>
              </motion.div>
            )}
            {!isSubmitting && curPage < 2 && (
              <LoginCePageWrapper
                curPage={curPage}
                setCurPage={setCurPage}
                isBslCheckout={isBslCheckout}
                error={error}
                handleBack={handleBack}
                isPartnerPortal={isPartnerPortal}
              >
                <PhoneEntry
                  showTerms={curPage === 1}
                  country={country}
                  prevPhoneEntry={prevPhoneEntry}
                  prevValue={prevValue}
                  setPrevValue={setPrevValue}
                  isPartnerPortal={isPartnerPortal}
                />
              </LoginCePageWrapper>
            )}
            {!isSubmitting && curPage === 2 && (
              <LoginCePageWrapper
                curPage={curPage}
                setCurPage={setCurPage}
                isBslCheckout={isBslCheckout}
                error={error}
                handleBack={handleBack}
              >
                <VerifyCode />
              </LoginCePageWrapper>
            )}
            {!isSubmitting && curPage === 3 && (
              <LoginCePageWrapper
                curPage={curPage}
                setCurPage={setCurPage}
                isBslCheckout={isBslCheckout}
                error={error}
                handleBack={handleBack}
                isPartnerPortal={isPartnerPortal}
              >
                <EmailEntry
                  isPartnerPortal={isPartnerPortal}
                  isInvite={siteArea === "partner_invite"}
                />
              </LoginCePageWrapper>
            )}
            {!isSubmitting && curPage === 4 && (
              <LoginCePageWrapper
                curPage={curPage}
                setCurPage={setCurPage}
                isBslCheckout={isBslCheckout}
                error={error}
                handleBack={handleBack}
                isPartnerPortal={isPartnerPortal}
              >
                <CreateAccount isPartnerPortal={isPartnerPortal} />
              </LoginCePageWrapper>
            )}
            {!isSubmitting && curPage === 5 && (
              <LoginCePageWrapper
                curPage={curPage}
                setCurPage={setCurPage}
                isBslCheckout={isBslCheckout}
                error={error}
                handleBack={handleBack}
              >
                <SignIn isPartnerPortal={isPartnerPortal} />
              </LoginCePageWrapper>
            )}
            {!isSubmitting && curPage === 6 && (
              <LoginCePageWrapper
                curPage={curPage}
                setCurPage={setCurPage}
                isBslCheckout={isBslCheckout}
                error={error}
                handleBack={handleBack}
              >
                <VerifyAnotherWay
                  handleResult={handleResult}
                  phoneToken={phoneToken}
                  verifyAction={verifyAction}
                  phoneToText={phoneToText}
                  isBslCheckout={isBslCheckout}
                  isPartnerPortal={isPartnerPortal}
                  inviteToken={inviteToken}
                />
              </LoginCePageWrapper>
            )}
            {!isSubmitting && curPage === 7 && (
              <LoginCePageWrapper
                curPage={curPage}
                setCurPage={setCurPage}
                isBslCheckout={isBslCheckout}
                error={error}
                handleBack={handleBack}
                authToken={authToken}
                handleResend={handleResend}
              >
                <VerifyYourEmail
                  handleResult={handleResult}
                  emailToken={emailToken}
                  isPartnerPortal={isPartnerPortal}
                  inviteToken={inviteToken}
                />
              </LoginCePageWrapper>
            )}
            {!isSubmitting && curPage === 8 && (
              <LoginCePageWrapper
                curPage={curPage}
                setCurPage={setCurPage}
                isBslCheckout={isBslCheckout}
                isRailsCheckout={
                  siteArea === "admin" || siteArea === "partner_portal"
                }
                error={error}
                handleBack={handleBack}
              ></LoginCePageWrapper>
            )}
          </AnimatePresence>
        </form>
      )}
    </Formik>
  );
};

export default withEventsLogger(NewLoginCreateAccount);
