import React, { memo, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Carousel1 from "../../../../assets/images/ambassador/Carousel-1.png";
import Carousel2 from "../../../../assets/images/ambassador/Carousel2.png";
import Carousel3 from "../../../../assets/images/ambassador/Carousel3.png";
import Carousel4 from "../../../../assets/images/ambassador/Carousel4.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { isMobileDevice } from "./";

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function CarouselSectionMobile() {
  const [slideIndex, setSlideIndex] = useState(0);
  const [arr, setArr] = useState([
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
  ]);
  const [carouselMinWidth, setCarouselMinWidth] = useState(
    window.innerWidth > 600
      ? (180 / 600) * 100
      : (180 / window.innerWidth) * 100,
  );
  const handleSlideChange = (index) => {
    if (!index) {
      const slider = document.querySelectorAll(".slider.animated")[0];
      const haldInnerWidth = window.innerWidth / 2;
      const halfActiveSlideWidth = 185 / 2;
      const position = 168 - Math.floor(haldInnerWidth - halfActiveSlideWidth);
      slider.style.transform = `translate3d(-${position}px, 0px, 0px)`;
      slider.style.transition = "none";
    } else {
      const slider = document.querySelectorAll(".slider.animated")[0];
      const haldInnerWidth = window.innerWidth / 2;
      const halfActiveSlideWidth = 185 / 2;
      const position =
        168 -
        Math.floor(haldInnerWidth - halfActiveSlideWidth) +
        haldInnerWidth -
        20;
      slider.style.transform = `translate3d(-${position}px, 0px, 0px)`;
      slider.style.transition = "all .35s ease-in-out";
    }
    setSlideIndex(index);
  };
  useEffect(() => {
    let isResizing = false; // Flag to track resizing
    const resizeWindow = () => {
      if (isMobileDevice()) {
        isResizing = true; // Set flag to true when resizing starts
        debounce(function () {
          isResizing = false; // Set flag back to false when debounce is done
        }, 1000)(); // Immediately invoke the debounced function
      }
    };
    window.addEventListener("resize", () => {
      if (!isResizing) {
        resizeWindow(); // Call resizeWindow only if not already resizing
        const slider = document.querySelectorAll(".slider.animated")[0];
        const haldInnerWidth = window.innerWidth / 2;
        const halfActiveSlideWidth = 185 / 2;
        const position =
          168 -
          Math.floor(haldInnerWidth - halfActiveSlideWidth) +
          haldInnerWidth -
          20;
        slider.style.transform = `translate3d(-${position}px, 0px, 0px)`;
        setCarouselMinWidth((180 / window.innerWidth) * 100);
        setSlideIndex(0);
      }
    });
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
  return (
    <div className="posts-container">
      <Carousel
        centerMode={true}
        autoPlay={true}
        interval={2500}
        infiniteLoop={true}
        onChange={handleSlideChange}
        showIndicators={false}
        selectedItem={slideIndex}
        centerSlidePercentage={41.5}
      >
        {slideIndex > -1
          ? arr.map((carousel, index) => {
              return (
                <div key={index}>
                  <img src={carousel} alt={`Image ${index + 1}`} />
                </div>
              );
            })
          : null}
      </Carousel>
    </div>
  );
}

export default memo(CarouselSectionMobile);
