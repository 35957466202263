import React, { useState, useContext, useRef, useEffect } from "react";
import Hamburger from "./Hamburger";
import { ListContext } from "./";
import { deleteCookie } from "../../../lib/helpers";
import Faqs, { faqsData } from "../2fa-user-flow/Faqs";
import PropTypes from "prop-types";
import useCookie from "@lib/use-cookie";

export default function PhoneMenu({
  setStep,
  step,
  profilePage,
  locationsCount,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showFaqs, setShowFaqs] = useState(false); // State for FAQ visibility
  const isLoggedIn = profilePage
    ? useState(false)[0]
    : useContext(ListContext).isLoggedIn;
  const [, updateSessionCookie] = useCookie("_equity_eats_session", null);
  const sideBarRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (sideBarRef.current && !sideBarRef.current.contains(e.target)) {
      handleCloseClick();
    }
  };

  const handleCloseClick = () => {
    setMenuOpen(false);
  };

  const redirectTo = (e) => {
    e.preventDefault();
    const name = e.target.getAttribute("name");
    switch (name) {
      case "home":
        window.location.href = "/";
        break;
      case "profile":
        if (!isLoggedIn) {
          setStep(2);
        } else {
          window.location.href = "/profile";
        }
        break;
      case "listing":
        window.location.href = "https://inkindcapital.com/";
        break;
      case "contact":
        window.location.href = "mailto:support@inkind.com";
        break;
      case "faq":
        setShowFaqs(!showFaqs);
        break;
      case "login-logout": {
        const isLoginText = e.target.innerText === "Login";
        if (isLoginText) {
          setStep(2);
        } else {
          deleteCookie();
          setStep(1);
          setMenuOpen(false);
          updateSessionCookie("", -365);
          window.location.reload();
        }
        break;
      }
      case "download":
        window.open("https://app.inkind.com/", "_blank", "noopener");
        break;
      case "instagram":
        window.open(
          "https://www.instagram.com/inkind_hospitality/",
          "_blank",
          "noopener",
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="phone-menu">
      {menuOpen ? (
        <div ref={sideBarRef} className="menu-items menuContainer">
          <div className="header">
            <button onClick={handleCloseClick} className="backButton">
              ← Back
            </button>
            <button onClick={handleCloseClick} className="closeButton">
              ✕
            </button>
          </div>
          <div className="menuList">
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default for spacebar
                  redirectTo(e); // Trigger click event on Enter or Space
                }
              }}
              onClick={redirectTo}
              className="menuItem"
              name="home"
            >
              Home
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default for spacebar
                  redirectTo(e); // Trigger click event on Enter or Space
                }
              }}
              onClick={redirectTo}
              className="menuItem"
              name="profile"
            >
              Your profile
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default for spacebar
                  redirectTo(e); // Trigger click event on Enter or Space
                }
              }}
              onClick={redirectTo}
              className="menuItem"
              name="listing"
            >
              List your restaurant
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default for spacebar
                  redirectTo(e); // Trigger click event on Enter or Space
                }
              }}
              onClick={redirectTo}
              className="menuItem"
              name="contact"
            >
              Contact Us
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default for spacebar
                  redirectTo(e); // Trigger click event on Enter or Space
                }
              }}
              onClick={redirectTo}
              className="menuItem"
              name="faq"
            >
              FAQ
            </div>
            {showFaqs && (
              <Faqs
                faqData={faqsData}
                hideTitle={true}
                locationsCount={locationsCount}
              />
            )}
            <div className="subMenu">
              <div
                onClick={redirectTo}
                name="login-logout"
                className="subMenuItem"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default for spacebar
                    redirectTo(e); // Trigger click event on Enter or Space
                  }
                }}
              >
                {profilePage || isLoggedIn ? "Logout" : "Login"}
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default for spacebar
                    redirectTo(e); // Trigger click event on Enter or Space
                  }
                }}
                onClick={redirectTo}
                name="download"
                className="subMenuItem"
              >
                Download inKind
              </div>
              <div
                onClick={redirectTo}
                name="instagram"
                className="subMenuItem"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default for spacebar
                    redirectTo(e); // Trigger click event on Enter or Space
                  }
                }}
              >
                Instagram
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Hamburger setMenuOpen={setMenuOpen} />
      )}
    </div>
  );
}

PhoneMenu.propTypes = {
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number,
  profilePage: PropTypes.bool,
  locationsCount: PropTypes.number,
};
