import React from "react";

export default function Hamburger({ setMenuOpen }) {
  const openMenu = () => {
    setMenuOpen(true);
  };
  return (
    <>
      <button onClick={openMenu} className="hamburger">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
    </>
  );
}
