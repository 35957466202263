import React from "react";
import PartnerPortalInputWrapper from "../partner-portal/molecules/PartnerPortalInputWrapper.tsx";
import ConditionalWrapper from "../../ui/atoms/ConditionalWrapper/ConditionalWrapper.jsx";
import InputLayout from "../../ui/molecules/InputLayout.tsx";
import { useFormikContext } from "formik";
import "detect-autofill";

const EmailEntry = ({ isPartnerPortal, isInvite }) => {
  const { values, handleBlur, handleChange, errors, submitForm, setErrors } =
    useFormikContext();
  const [didAutoComplete, setDidAutoComplete] = React.useState(false);

  React.useEffect(() => {
    if (didAutoComplete && values.email) {
      if (errors.email) {
        setErrors({});
      }
      setDidAutoComplete(false);
      submitForm();
    }
  }, [didAutoComplete, values.email]);

  React.useEffect(() => {
    document.addEventListener("onautocomplete", function (e) {
      setDidAutoComplete(e.target.hasAttribute("autocompleted"));
    });
  }, []);

  return (
    <ConditionalWrapper
      condition={isPartnerPortal}
      wrapper1={(children) => (
        <InputLayout label="Email" error={errors.email}>
          {children}
        </InputLayout>
      )}
      wrapper2={(children) => (
        <PartnerPortalInputWrapper label="Email" name={"email"}>
          {children}
        </PartnerPortalInputWrapper>
      )}
    >
      {isInvite ? (
        <div
          type="email"
          name="email"
          aria-label="email"
          className="floating-label-input login-ca__disabled-field"
        >
          {values.email}
        </div>
      ) : (
        <input
          type="email"
          name="email"
          aria-label="email"
          placeholder=" "
          className="floating-label-input"
          value={values.email}
          onChange={(val) => {
            if (errors.email) {
              setErrors({});
            }
            handleChange(val);
          }}
          onBlur={handleBlur}
          autoComplete="email"
        />
      )}
    </ConditionalWrapper>
  );
};

export default EmailEntry;
