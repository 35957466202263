import React from "react";
import Select from "react-select";

const VisibilityItemRow = ({
  itemName,
  menuKey,
  index,
  optionsSelectedFormatted,
  handleValueChange,
}) => {
  const options = [
    { value: "takeout", label: "Takeout" },
    { value: "delivery", label: "Delivery" },
    { value: "dinein", label: "Dine In" },
  ];

  return (
    <tr
      className={`menu-visibility__tr ${
        index % 2 === 0 ? "menu-visibility__tr--odd" : ""
      }`}
    >
      <td className="menu-visibility__td">{itemName}</td>
      <td className="menu-visibility__td">
        <Select
          defaultValue={optionsSelectedFormatted}
          isMulti
          onChange={(val) => {
            handleValueChange(val, menuKey);
          }}
          name={itemName + "_select"}
          options={options}
          styles={{
            control: (provided, state) => ({
              ...provided,
              background: "none",
              fontFamily: "Rethink-Sans-Medium",
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              padding: "6px 8px",
              fontFamily: "Rethink-Sans-Medium",
            }),
          }}
          placeholder="Select"
        />
      </td>
    </tr>
  );
};

export default VisibilityItemRow;
