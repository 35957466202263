/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import InkindLogoBlack from "../../../../assets/images/ambassador/inKindLogo.svg";
import PropTypes from "prop-types";

export default function TopSection({
  heaaderText = "",
  paraTextBold = "",
  paraTextLight = "",
}) {
  return (
    <div>
      <div
        className="top-content"
        css={css`
          text-align: center;
        `}
      >
        <div
          className="inkind-logo"
          css={css`
            margin-bottom: 70px;
          `}
        >
          <img src={InkindLogoBlack} alt="InKind Logo" />
        </div>
        {heaaderText && (
          <h1
            css={css`
              max-width: 450px;
              margin: 0 auto;
              margin-bottom: 20px;
              font-size: 34px;
            `}
            className="main-top-heading-content"
          >
            {heaaderText}
          </h1>
        )}
        {paraTextBold && (
          <p
            css={css`
              margin-bottom: 40px !important;
              max-width: 480px;
              margin: 0 auto;
            `}
            className="main-top-para-content"
          >
            <span
              css={css`
                font-weight: 600;
              `}
            >
              {paraTextBold}
            </span>
            {` ${paraTextLight}`}
          </p>
        )}
      </div>
    </div>
  );
}

TopSection.propTypes = {
  heaaderText: PropTypes.string,
  paraTextBold: PropTypes.string,
  paraTextLight: PropTypes.string,
};
