import React from "react";
import { PartnerPortalTooltipProps } from "../helpers/types";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  alt: "Tooltip";
  type: "button";
}

function Button({ alt, type, ...props }: ButtonProps) {
  return <button type="button" {...props} />;
}

const PartnerPortalTooltip = ({
  tooltip,
  children,
  position,
}: PartnerPortalTooltipProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <span className="pp-input__tooltip-button-container">
      {/* <button
        className="pp-input__tooltip-icon"
        alt="Tooltip"
        type="button"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      /> */}
      {children ? (
        <button
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {children}
        </button>
      ) : (
        <Button
          className="pp-input__tooltip-icon"
          alt="Tooltip"
          type="button"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        />
      )}

      {showTooltip ? (
        <div
          className={`pp-input__tooltip-container ${position === "left" ? "pp-input__tooltip-container-left" : "pp-input__tooltip-container-right"}`}
        >
          <div className="pp-input__tooltip">{tooltip}</div>
        </div>
      ) : null}
    </span>
  );
};
export default PartnerPortalTooltip;
