import React, { ComponentType } from "react";
import useFetchApi from "../../hooks/useFetchApi";
import { Event, Response } from "./types";
import useDeviceId from "@/events/useDeviceId";
import useSessionId from "@/events/useSessionId";

export interface WithEventsLoggerProps {
  logEvents: (events: Partial<Event>[]) => void;
}

function withEventsLogger<T extends WithEventsLoggerProps>(
  WrappedComponent: ComponentType<T>,
) {
  // Returning a component that wraps the original component
  return (props: Omit<T, keyof WithEventsLoggerProps>) => {
    const deviceId = useDeviceId();
    const sessionId = useSessionId();
    const { fetchData } = useFetchApi<Response>();

    const logEvents = async (events: Partial<Event>[]) => {
      const eventsPayload = events.map((event) => ({
        ...event,
        subscriber_id: null,
        time: Date.now(),
        platform: "Web",
        custom_fields: {
          property_groups: [],
          user_properties: [],
          event_properties: [],
        },
      }));

      await fetchData("/api/v5/events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-InKind-Session": `${sessionId}`,
          "X-Inkind-Device": deviceId,
        },
        body: JSON.stringify({
          events: eventsPayload,
          time: Date.now(),
        }),
      });
    };

    return <WrappedComponent {...(props as T)} logEvents={logEvents} />;
  };
}

export default withEventsLogger;
