export const getUrlParams = (hash) => {
  let parsedObj;
  if (hash) {
    const [_, params] = hash.substring(1).split("?");
    const urlParams = new URLSearchParams(params);
    const paramValue = urlParams.get("token");
    if (paramValue) {
      const decodedString = window.atob(paramValue);
      parsedObj = JSON.parse(decodedString);
    }
  }
  return parsedObj;
};
