import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  PaperAirplaneIcon,
  EnvelopeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { forgotPassword } from "../services/auth";
import { InKindNetworkError } from "../../../helpers/InKindNetworkError";
const ForgotPassword = ({ email, setEmail, handleAmplitude }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [emailError, setEmailError] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const handleSubmit = async (event) => {
    handleAmplitude("click_forgot_password", { email: email });
    event.preventDefault();
    if (/^[^@]+@[^@]+\.[^@]+$/.test(email) === false) {
      setEmailError("Invalid email address.");
      return false;
    }
    forgotPassword(email)
      .then((resp) => setSuccessMessage(resp?.data?.message))
      .catch((error) => {
        const err = InKindNetworkError(error);
        setEmailError(`• ${err?.message}`);
      });
  };

  React.useEffect(() => {
    if (!isOpen) {
      setSuccessMessage(null);
    }
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="forgot-pw"
      >
        Forgot password?
      </button>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="dialog" onClose={() => setIsOpen(false)}>
          <div className=" inner">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="dialog-overlay " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="trick-span" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child as={React.Fragment}>
              <div className="reset-dialog-block reset">
                {successMessage ? (
                  <>
                    <div className="email-row">
                      <button onClick={() => setIsOpen(false)}>
                        <XMarkIcon className="xicon" />
                      </button>
                      <div className="head">Reset instructions sent</div>
                      <div className="reset-info">{successMessage}</div>
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        className="submit-button"
                      >
                        Log in
                      </button>
                    </div>
                    {emailError && <span className="error">{emailError}</span>}
                  </>
                ) : (
                  <>
                    <div className="email-row">
                      <button onClick={() => setIsOpen(false)}>
                        <XMarkIcon className="xicon" />
                      </button>
                      <div className="head">Reset your inKind password</div>
                      <div className="reset-info">
                        Enter the email for your account and we'll send you
                        reset instructions.
                      </div>
                      <input
                        value={email}
                        onChange={({ target }) => {
                          setEmail(target.value);
                          setEmailError(null);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleSubmit(event);
                          }
                        }}
                        placeholder="Email"
                        type="email"
                        className="email-input"
                      />
                      {emailError && (
                        <span className="error reset-error">{emailError}</span>
                      )}
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="submit-button"
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ForgotPassword;
