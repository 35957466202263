/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import BrnadLogo from "../../../../assets/images/ambassador/brand-logo.svg";
import BgImage from "../../../../assets/images/ambassador/background-2fa-desktop.svg";
import { calculateFontColorFor } from "./";

export default function TopSection({ initialValues }) {
  return (
    <div
      className="container-image"
      css={css`
        &::before {
          background-image: url(${initialValues?.brandHeroImageUrl ||
          BgImage}) !important;
        }
      `}
    >
      <img
        src={initialValues.brandDarkLogo || BrnadLogo}
        alt="brand-logo"
        css={css`
          width: 110px;
        `}
      />
    </div>
  );
}
