/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState, useRef, useEffect } from "react";
import TopSection from "./TopSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormikContext } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import VerifyCode from "../../new-login-create-account/VerifyCode";
import { object, string } from "yup";
import { handlePost } from "../../new-login-create-account/utilities";
import { MainHeader, BackButton } from "./SignUpScreen";

export default function VerificationCode() {
  const formikRef = useRef();
  const [buttonActive, setButtonActive] = useState(false);
  const [phoneToken, setPhoneToken] = useState("");
  const [error, setError] = useState("");

  const initialValues = {
    sixDigitCode: 0,
  };
  const handleResend = (e) => {
    e.preventDefault();
    console.log("Resend code");
  };
  const userSchema = object({
    sixDigitCode: string().test({
      name: "valid",
      exclusive: false,
      message: "Code must be six digits long.",
      test: function (value) {
        // return curPage !== 2 || value?.length === 6;
        return value?.length === 6;
      },
    }),
  });
  const handleSubmit = async (values) => {
    let path;
    let body = {};
    path = "/api/v5/verifications/phone/verify";
    body.verification = "otp_code";
    body.verification_code = values.sixDigitCode;
    body.phone_token = phoneToken;
    body.verify_action = "verify_sms_otp" || verifyAction;
    // switch (true) {
    //   case curPage === 0:
    //     path = "/api/v5/verifications/phone";
    //     body.phone_number = "+".concat(values.phone.replace(/\D/g, ""));
    //     body.cc = values.countryCode;
    //     body.auth_source = siteArea;
    //     setPrevPhoneEntry(body.phone_number);
    //     break;
    //   case curPage === 1:
    //     path = "/api/v5/verifications/phone";
    //     body.phone_number = "+".concat(values.phone.replace(/\D/g, ""));
    //     body.cc = values.countryCode;
    //     body.opt_in_sms = values.agreeToComm;
    //     body.verify_action = verifyAction;
    //     body.auth_source = siteArea;
    //     break;
    //   case curPage === 2:
    // path = "/api/v5/verifications/phone/verify";
    // body.verification = "otp_code";
    // body.verification_code = values.sixDigitCode;
    // body.phone_token = phoneToken;
    // body.verify_action = verifyAction;
    //     break;
    //   default:
    //     break;
    // }
    let bodyJson = JSON.stringify(body);
    const result = await handlePost(path, bodyJson, setError);
    if (!result) {
      formikRef.current.setSubmitting(false);
      return;
    }
    handleResult(result);
    if (formikRef.current.values?.sixDigitCode?.length === 6) {
      formikRef.current.setFieldValue("sixDigitCode", "");
    }
    formikRef.current.setSubmitting(false);
  };

  return (
    <div
      className="verification-code-screen signup-screen"
      css={css`
        min-height: 100vh;
      `}
    >
      <TopSection />
      <div
        className="container"
        css={css`
          width: 100%;
        `}
      >
        <div>
          <div
            className="capture-db__btn-back"
            role="button"
            onClick={() => window?.history?.back()}
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: 8px;
            `}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <h1
              className="capture-db__header-name"
              css={css`
                font-weight: 600;
                font-size: 25px;
                color: #2e2e2d;
                text-align: center;
                flex: 1;
                padding-right: 24px;
              `}
            >
              Enter verification code
            </h1>
          </div>
          <p
            css={css`
              margin-bottom: 30px;
              font-size: 14px;
            `}
          >
            We texted a code to (505) 621 8033
          </p>
        </div>

        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          validationSchema={userSchema}
          validateOnChange={false}
          validateOnBlur={false}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={() => null}
        >
          {({ isSubmitting }) => (
            <div className="">
              <div className="form">
                <form className="login-ca__form">
                  <AnimatePresence>
                    {isSubmitting && (
                      <motion.div
                        key="loading"
                        className="login-ca__loader-container"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                      >
                        <div className="login-ca__loader"></div>
                      </motion.div>
                    )}
                    <VerifyCode />
                  </AnimatePresence>
                </form>
              </div>
              <div
                className="message"
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 15px;
                  font-weight: 500;
                  margin-bottom: 40px;
                `}
              >
                <p
                  css={css`
                    margin: 0;
                  `}
                >
                  Didn’t get a code?
                </p>
                <button
                  onClick={handleResend}
                  type="button"
                  css={css`
                    border: none;
                    background: transparent;
                    margin: 0;
                    padding: 0;
                    color: #007aff;
                  `}
                >
                  Tap to text us instead
                </button>
              </div>
              <SubmitButton
                handleSubmit={handleSubmit}
                buttonActive={buttonActive}
                setButtonActive={setButtonActive}
              />
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

export function VerificationCodeHeader({ number, setCurPage }) {
  return (
    <MainHeader>
      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 8px;
          `}
        >
          <BackButton setCurPage={setCurPage} />
          <h1
            className="capture-db__header-name"
            css={css`
              font-weight: 600;
              font-size: 20px;
              color: #2e2e2d;
              text-align: center;
              flex: 1;
              padding-right: 18px;
            `}
          >
            Enter verification code
          </h1>
        </div>
        <p
          css={css`
            margin-bottom: 30px;
            font-size: 14px;
          `}
        >{`We texted a code to ${number}`}</p>
      </div>
    </MainHeader>
  );
}

function SubmitButton({ handleSubmit, buttonActive, setButtonActive }) {
  const { values } = useFormikContext();
  useEffect(() => {
    if (values.sixDigitCode?.length === 6) {
      setButtonActive(true);
    } else {
      if (buttonActive) setButtonActive(false);
    }
  }, [values.sixDigitCode]);
  return (
    <button
      type="button"
      disabled={!buttonActive}
      onClick={handleSubmit}
      className={`${buttonActive ? "active" : ""} next-button`}
    >
      Next
    </button>
  );
}
