import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/css";

const LoaderWrapper = styled.div`
  position: relative;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    ${(p) =>
      p.style === "circle"
        ? `
      display: block;
      padding-top: 100%;
      content: '';
    `
        : ``};
  }

  .circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
  }

  .loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation:
      dash 1.5s ease-in-out infinite,
      color 6s ease-in-out infinite;
    animation:
      dash 1.5s ease-in-out infinite,
      color 6s ease-in-out infinite;
    stroke-linecap: round;
    stroke: var(--color-green);
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @-webkit-keyframes color {
    0% {
      stroke: var(--color-green);
    }
    40% {
      stroke: var(--color-green);
    }
    66% {
      stroke: var(--color-green);
    }
    80%,
    90% {
      stroke: var(--color-green);
    }
  }
  @keyframes color {
    0% {
      stroke: var(--color-green);
    }
    40% {
      stroke: var(--color-green);
    }
    66% {
      stroke: var(--color-green);
    }
    80%,
    90% {
      stroke: var(--color-green);
    }
  }

  .dot-flashing {
    position: relative;
    width: ${(p) => p.size / 3}px;
    height: ${(p) => p.size / 3}px;
    border-radius: 50%;
    background-color: ${(p) => p.color};
    color: ${(p) => p.color};
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    position: absolute;
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: ${(p) => p.size / 3}px;
    height: ${(p) => p.size / 3}px;
    background-color: ${(p) => p.color};
    animation: dotFlashing 1s infinite alternate;
    color: ${(p) => p.color};
    top: 50%;
    transform: translateY(-50%);
  }

  .dot-flashing::before {
    left: -${(p) => p.size / 3 + 2}px;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: ${(p) => p.size / 3 + 2}px;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: ${(p) => p.color};
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
`;

const Loader = ({ style, className, size, color }) => {
  return (
    <LoaderWrapper className={className} size={size} color={color}>
      {style === "circle" && (
        <svg className="circular-loader" viewBox="25 25 50 50">
          <circle
            className="loader-path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
          />
        </svg>
      )}
      {style === "dots" && <div className="dot-flashing"></div>}
    </LoaderWrapper>
  );
};

Loader.defaultProps = {
  size: 100,
  style: "circle",
  color: "var(--color-green)",
};

export default Loader;
