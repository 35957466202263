import React from "react";
import mapIcon from "../../../assets/images/map_v2/map-logo.svg";
import infoIcon from "../../../assets/images/map_v2/list-logo.svg";
import hamburgerMenu from "../../../assets/images/map_v2/hamburger-menu.svg";
import arrowLeft from "../../../assets/images/map_v2/arrow-left-white.svg";
import { Listbox } from "@headlessui/react";
import SelectMenu from "./SelectMenu";
import MobileSelectMenu from "./MobileSelectMenu";

const MapHeaderContainer = ({
  infoOpenMobile,
  setInfoOpenMobile,
  hamburgerState,
  widget,
  userLocation,
  staticLocation,
  setUserLocation,
  map,
  setEnteredThroughButton,
  colorMode,
  selectedCuisines,
  selectedMoods,
  setSelectedCuisines,
  setSelectedMoods,
  setMoodsIsOpen,
  moodsIsOpen,
  setCuisinesIsOpen,
  cuisinesIsOpen,
  placeHolder,
  initSelectedTag,
  logo,
  ikname,
  inkindStateDomain,
}) => {
  const selectCategoriesInit = [
    {
      name: "Asian",
      id: 0,
      tags: [118, 43, 11, 13, 15, 29, 119, 61, 24, 21, 20],
    },
    { name: "Bars & Breweries", id: 1, tags: [58, 81, 104, 105, 39, 126, 109] },
    { name: "Lounge & Club", id: 2, tags: [18, 116, 124, 86, 80, 84, 58, 79] },
    { name: "Italian", id: 3, tags: [14, 64, 60] },
    { name: "Coffee/Cafe", id: 4, tags: [115, 36, 34, 123, 35, 54, 55] },
    { name: "Latin/Spanish", id: 5, tags: [40, 47, 117, 49, 57, 16, 131, 12] },
    { name: "Healthy", id: 6, tags: [100, 1, 8, 63, 107, 3, 108] },
    { name: "New American", id: 7, tags: [9, 28, 7, 17, 30] },
    { name: "Mediterranean", id: 8, tags: [37, 4, 53, 121, 22] },
  ];

  const [selectCategories, setSelectCategories] =
    React.useState(selectCategoriesInit);

  React.useEffect(() => {
    moodsIsOpen === true ? setCuisinesIsOpen(false) : null;
  }, [moodsIsOpen]);

  const handleSelectInit = () => {
    for (let i = 0; i < selectCategories.length; i++) {
      //if this tag is part of a larger bucket, find it and show the whole bucket
      if (selectCategories[i].tags.includes(initSelectedTag.id)) {
        setSelectedCuisines(selectCategories[i]);
        break;
      }
      //otherwise, add this tag to beginning of the dropdown and show it
      const newSelectCategories = [...selectCategories];
      const newTag = {
        name: initSelectedTag.name,
        id: 9,
        tags: [initSelectedTag.id],
      };
      newSelectCategories.unshift(newTag);
      setSelectCategories(newSelectCategories);
      setSelectedCuisines(newSelectCategories[0]);
    }
  };

  React.useEffect(() => {
    if (!!initSelectedTag) {
      handleSelectInit();
    }
  }, [initSelectedTag]);

  React.useEffect(() => {
    cuisinesIsOpen === true ? setMoodsIsOpen(false) : null;
  }, [cuisinesIsOpen]);

  const handleZoom = () => {
    let latLng = new google.maps.LatLng(
      staticLocation.location[0],
      staticLocation.location[1],
    );
    if (
      userLocation.location[0] !== staticLocation.location[0] &&
      userLocation.location[1] !== staticLocation.location[1]
    ) {
      setUserLocation(staticLocation);
    }
    map.panTo(latLng);
    map.setZoom(14);
  };

  const handleOpen = () => {
    if (!!infoOpenMobile) {
      setInfoOpenMobile(false);
    } else {
      setInfoOpenMobile(true);
      setEnteredThroughButton(true);
    }
  };
  return (
    <div
      className={`map-header-container ${
        widget === true && colorMode === "light"
          ? "light-header-container"
          : colorMode === "black"
            ? "black-header-container"
            : ""
      }`}
      style={{
        borderRadius: widget === true ? "24px" : "0px",
        padding: widget === true ? "12px" : "20px",
        marginBottom: widget === true ? "24px" : "0px",
      }}
    >
      <nav
        className="map-navbar"
        style={{ display: widget === true ? "none" : "" }}
      >
        {document?.referrer?.length > 0 &&
        document.referrer != window.location.href ? (
          <a href={document.referrer} className="nav-text back-container">
            <img src={arrowLeft} />
            <div>Back</div>
          </a>
        ) : (
          <div className="nav-text back-container"></div>
        )}
        <img src={logo} />
        <img src={hamburgerMenu} className="hamburger-menu" />
        <div className="nav-text">
          <a
            href={inkindStateDomain}
            className="download-btn-container"
            target="blank"
          >
            <div className="download-btn">Download {ikname}</div>
          </a>
        </div>
      </nav>
      <div
        className="nav-hamburger-open"
        style={{ display: hamburgerState === true ? "flex" : "none" }}
      >
        {document?.referrer?.length > 0 &&
          document.referrer != window.location.href && (
            <a href={document.referrer}>Back</a>
          )}
        <a href={inkindStateDomain} target="blank">
          Download {ikname}
        </a>
      </div>
      <div
        className="map-search-bar"
        style={{ paddingTop: widget === true ? "0px" : "24px" }}
      >
        <div className="pac-input-container">
          <input
            id="pac-input"
            className="pac-input"
            type="text"
            placeholder={placeHolder}
          />
          <div
            className="zoom-to"
            onClick={() => {
              handleZoom();
            }}
          ></div>
        </div>

        <SelectMenu
          selectedItems={selectedCuisines}
          colorMode={colorMode}
          widget={widget}
          setSelectedItems={setSelectedCuisines}
          selectCategories={selectCategories}
          setOther={setSelectedMoods}
          isOpen={cuisinesIsOpen}
          setIsOpen={setCuisinesIsOpen}
          name="Cuisines"
        />

        {/*<SelectMenu
          selectedItems={selectedMoods}
          setSelectedItems={setSelectedMoods}
          setOther={setSelectedCuisines}
          items={moods}
          isOpen={moodsIsOpen}
          setIsOpen={setMoodsIsOpen}
          name="Moods"
          />*/}

        <MobileSelectMenu
          selectedMoods={selectedMoods}
          setSelectedMoods={setSelectedMoods}
          selectedCuisines={selectedCuisines}
          setSelectedCuisines={setSelectedCuisines}
          cuisines={selectCategories}
          colorMode={colorMode}
        />

        <a
          className={
            colorMode === "black"
              ? "map-toggle-container-black map-toggle-container"
              : "map-toggle-container"
          }
          onClick={() => {
            handleOpen();
          }}
        >
          <img src={infoOpenMobile === true ? mapIcon : infoIcon} />
        </a>
      </div>
    </div>
  );
};

export default MapHeaderContainer;
