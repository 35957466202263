import React from "react";

export const PhoneIcon = ({
  size,
  name = "",
  color = "currentColor",
  className = "",
}) => {
  return (
    <svg
      width={size || 43}
      height={size || 43}
      viewBox="0 0 24 40"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.62015 39.6128C4.09881 39.6128 2.89808 39.1916 2.01797 38.3492C1.15043 37.5068 0.716656 36.3438 0.716656 34.8602V5.57126C0.716656 4.10022 1.15043 2.94349 2.01797 2.1011C2.89808 1.24613 4.09881 0.818646 5.62015 0.818646H18.7653C20.2866 0.818646 21.4811 1.24613 22.3486 2.1011C23.2287 2.94349 23.6688 4.10022 23.6688 5.57126V34.8602C23.6688 36.3438 23.2287 37.5068 22.3486 38.3492C21.4811 39.1916 20.2866 39.6128 18.7653 39.6128H5.62015ZM5.88419 37.312H18.5013C19.4442 37.312 20.1546 37.0794 20.6324 36.6142C21.1102 36.149 21.3491 35.4574 21.3491 34.5396V5.91074C21.3491 4.98033 21.1102 4.28881 20.6324 3.83618C20.1546 3.37098 19.4442 3.13838 18.5013 3.13838H5.88419C4.94121 3.13838 4.23083 3.37098 3.75305 3.83618C3.27527 4.28881 3.03639 4.98033 3.03639 5.91074V34.5396C3.03639 35.4574 3.27527 36.149 3.75305 36.6142C4.23083 37.0794 4.94121 37.312 5.88419 37.312ZM8.29821 35.9918C8.08447 35.9918 7.90216 35.9164 7.75128 35.7655C7.61298 35.6272 7.54383 35.4511 7.54383 35.2374C7.54383 35.0237 7.61298 34.8476 7.75128 34.7093C7.90216 34.571 8.08447 34.5019 8.29821 34.5019H16.1249C16.3261 34.5019 16.4959 34.571 16.6342 34.7093C16.7725 34.8476 16.8416 35.0237 16.8416 35.2374C16.8416 35.4511 16.7725 35.6272 16.6342 35.7655C16.4959 35.9164 16.3261 35.9918 16.1249 35.9918H8.29821ZM9.99558 6.58968C9.66868 6.58968 9.39836 6.48281 9.18461 6.26907C8.97087 6.05533 8.864 5.78501 8.864 5.45811C8.864 5.15635 8.97087 4.8986 9.18461 4.68486C9.39836 4.47112 9.66868 4.36425 9.99558 4.36425H14.4087C14.7105 4.36425 14.9682 4.47112 15.182 4.68486C15.4083 4.8986 15.5214 5.15635 15.5214 5.45811C15.5214 5.78501 15.4083 6.05533 15.182 6.26907C14.9682 6.48281 14.7105 6.58968 14.4087 6.58968H9.99558Z" />
    </svg>
  );
};

export const GenerateLinkIcon = ({
  size,
  name = "",
  color = "currentColor",
  className = "",
}) => {
  return (
    <svg
      width={size || 43}
      height={size || 43}
      viewBox="0 0 58.995 58.995"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.927,41.929c-0.524,0.524-0.975,1.1-1.365,1.709l-17.28-10.489c0.457-1.144,0.716-2.388,0.716-3.693
	c0-1.305-0.259-2.549-0.715-3.693l17.284-10.409C40.342,18.142,43.454,20,46.998,20c5.514,0,10-4.486,10-10s-4.486-10-10-10
	s-10,4.486-10,10c0,1.256,0.243,2.454,0.667,3.562L20.358,23.985c-1.788-2.724-4.866-4.529-8.361-4.529c-5.514,0-10,4.486-10,10
	s4.486,10,10,10c3.495,0,6.572-1.805,8.36-4.529L37.661,45.43c-0.43,1.126-0.664,2.329-0.664,3.57c0,2.671,1.04,5.183,2.929,7.071
	c1.949,1.949,4.51,2.924,7.071,2.924s5.122-0.975,7.071-2.924c1.889-1.889,2.929-4.4,2.929-7.071s-1.04-5.183-2.929-7.071
	C50.169,38.029,43.826,38.029,39.927,41.929z M46.998,2c4.411,0,8,3.589,8,8s-3.589,8-8,8s-8-3.589-8-8S42.586,2,46.998,2z
	 M11.998,37.456c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8S16.409,37.456,11.998,37.456z M52.654,54.657
	c-3.119,3.119-8.194,3.119-11.313,0c-1.511-1.511-2.343-3.521-2.343-5.657s0.832-4.146,2.343-5.657
	c1.56-1.56,3.608-2.339,5.657-2.339s4.097,0.779,5.657,2.339c1.511,1.511,2.343,3.521,2.343,5.657S54.166,53.146,52.654,54.657z"
      />
    </svg>
  );
};

export const ShareNSaveIcon = ({
  size,
  name = "",
  color = "currentColor",
  className = "",
}) => {
  return (
    <svg
      width={size || 52}
      height={size || 52}
      viewBox="0 0 408.113 408.113"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M203.174,342.483c-76.33,0-138.429-62.097-138.429-138.424c0-76.33,62.099-138.429,138.429-138.429
	c76.327,0,138.424,62.099,138.424,138.429C341.598,280.387,279.501,342.483,203.174,342.483z M203.174,75.63
	c-70.815,0-128.429,57.613-128.429,128.429c0,70.813,57.613,128.424,128.429,128.424c70.813,0,128.424-57.611,128.424-128.424
	C331.598,133.243,273.987,75.63,203.174,75.63z M35.277,336.753H21.132c-2.762,0-5-2.239-5-5V176.161
	C6.603,171.921,0,162.824,0,152.311V82.113c0-2.761,2.238-5,5-5s5,2.239,5,5v65.198h5.468V82.113c0-2.761,2.238-5,5-5s5,2.239,5,5
	v65.198h5.474V82.113c0-2.761,2.238-5,5-5s5,2.239,5,5v65.198h5.469V82.113c0-2.761,2.238-5,5-5s5,2.239,5,5v70.198
	c0,10.513-6.604,19.61-16.133,23.851v155.591C40.277,334.514,38.039,336.753,35.277,336.753z M26.132,326.753h4.146V178.703h-4.146
	V326.753z M10.866,157.311c2.353,6.602,9.236,11.393,17.339,11.393s14.986-4.791,17.339-11.393H10.866z M384.691,335.464h-14.6
	c-2.762,0-5-2.239-5-5V79.242c0-2.063,1.268-3.916,3.191-4.662c1.928-0.747,4.108-0.234,5.501,1.29
	c1.122,1.229,27.53,30.567,32.242,72.151c4.586,40.435,0.21,107.148,0.021,109.968c-0.176,2.626-2.357,4.667-4.989,4.667h-11.367
	v67.808C389.691,333.225,387.453,335.464,384.691,335.464z M375.092,325.464h4.6v-62.808h-4.6V325.464z M375.092,252.656h21.26
	c0.916-16.083,3.539-70.003-0.261-103.508c-2.673-23.579-13.229-43.257-20.999-54.955V252.656z M203.174,299.064
	c-52.389,0-95.01-42.619-95.01-95.005c0-52.389,42.621-95.01,95.01-95.01c52.386,0,95.005,42.622,95.005,95.01
	C298.179,256.445,255.56,299.064,203.174,299.064z M203.174,119.049c-46.875,0-85.01,38.135-85.01,85.01
	c0,46.872,38.135,85.005,85.01,85.005c46.872,0,85.005-38.133,85.005-85.005C288.179,157.184,250.046,119.049,203.174,119.049z
	 M104.805,140.978c-0.894,0-1.799-0.239-2.615-0.742c-2.353-1.447-3.085-4.527-1.638-6.879
	c16.541-26.879,47.096-41.172,48.388-41.767c2.509-1.156,5.479-0.059,6.634,2.45c1.154,2.507,0.059,5.475-2.447,6.631l0,0
	c-0.289,0.134-29.134,13.675-44.059,37.927C108.123,140.133,106.483,140.978,104.805,140.978z"
      />
    </svg>
  );
};
