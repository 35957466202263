/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from "react";
import { motion } from "framer-motion";
import SubmitButton from "../../new-login-create-account/SubmitButton";
import LogoutButton from "../../new-login-create-account/LogoutButton";
import ikLogo from "../../../../assets/images/checkout/inKind_logo.svg";
import { useFormikContext } from "formik";
import { formatPhoneNumber } from "@lib/helpers";
import { AddPhoneNumberHeader, BackButton, MainHeader } from "./SignUpScreen";
import { VerificationCodeHeader } from "./VerificationCode";

const LoginCePageWrapperUserFlowMobile = ({
  children,
  curPage,
  setCurPage,
  isBslCheckout,
  error,
  handleBack,
  isRailsCheckout,
  handleResend,
  accentColor,
}) => {
  const { values } = useFormikContext();
  const [showResend, setShowResend] = React.useState(false);
  const [success, setSuccess] = React.useState("");

  React.useEffect(() => {
    if (curPage === 7) {
      const trackTime = setTimeout(() => {
        setShowResend(true);
      }, 60000);

      //clear timeout on unmount
      return () => {
        clearTimeout(trackTime);
      };
    }
  });

  React.useEffect(() => {
    if (success.length > 0) {
      setShowResend(false);
      const trackTime = setTimeout(() => {
        setSuccess("");
        setShowResend(true);
      }, 60000);

      //clear timeout on unmount
      return () => {
        clearTimeout(trackTime);
      };
    }
  }, [success]);

  //is this user creating an account, using 2FA for the first time, or a normal returning user?

  //What should the view's header display?
  const loginHeaderText = {
    0: "",
    1: "",
    2: "Enter verification code",
    3: "Enter your email",
    4: "Complete your inKind account",
    5: "Sign In",
    6: "Verify another way to proceed",
    7: "Verify your email",
  };

  const loginSubHeaderText = {
    0: "",
    1: "",
    2: `We texted a code to ${formatPhoneNumber(values.phone)}.`,
    3: "",
    4: "You'll redeem your balance in-store using the inKind app.",
    5: "",
    6: "",
    7: `When you have a moment, check your ${values.email} inbox for a link to verify your email address.`,
  };

  const assembleBody = () => {
    return encodeURIComponent(
      `Hello,\n\nI'm having trouble verifying my email address (${values.email}) while logging in to inKind at the following url:\n\n${window.location.href}`,
    );
  };
  return (
    <motion.div
      key={`page-${curPage}`}
      className={`login-ca__container inner-col mobile-login-ca_container-${curPage} ${
        curPage === 7 || curPage === 6 ? "login-ca__confirm" : ""
      }`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {curPage < 2 && !!isBslCheckout ? (
        <img className="login-ca__ik-logo" src={ikLogo} alt="InKind Logo" />
      ) : null}
      {curPage < 2 ? (
        <MainHeader>
          <AddPhoneNumberHeader setCurPage={setCurPage} />
        </MainHeader>
      ) : null}
      {curPage === 7 ? children : null}
      {curPage === 2 ? (
        <MainHeader>
          <VerificationCodeHeader
            setCurPage={setCurPage}
            number={formatPhoneNumber(values.phone)}
            accentColor={accentColor}
          />
        </MainHeader>
      ) : null}
      {curPage === 6 || curPage === 5 || curPage === 4 ? (
        <BackButton setCurPage={setCurPage} />
      ) : null}
      {!isBslCheckout && curPage === 3 ? (
        <MainHeader>
          <EmailHeader setCurPage={setCurPage} />
        </MainHeader>
      ) : null}
      {!isBslCheckout && curPage > 2 && curPage !== 3 ? (
        <h3
          className={`login-ca__header login-ca__head ${
            curPage === 7 || curPage === 6 ? "center-header" : ""
          }`}
        >
          {loginHeaderText[curPage]}
        </h3>
      ) : null}

      {curPage > 2 && loginSubHeaderText[curPage]?.length > 0 ? (
        <div className="login-ca__subhead">{loginSubHeaderText[curPage]}</div>
      ) : null}
      {curPage === 2 ? (
        <div
          className="login-ca__subhead"
          css={css`
            display: flex;
            align-items: Center;
            justify-content: space-between;
            font-size: 13px;
            position: relative;
          `}
        >
          <p>Didn't get a code? </p>
          <button className="login-ca__verify" onClick={() => setCurPage(6)}>
            Tap to text us instead
          </button>
        </div>
      ) : null}
      {curPage !== 7 ? children : null}
      {error?.length > 0 ? <div className="error">{error}</div> : null}
      {curPage !== 6 && curPage !== 8 ? (
        <SubmitButton
          verb={curPage === 7 ? "I have verified my email" : "Next"}
          isBslCheckout={isBslCheckout}
          accentColor={accentColor}
          curPage={curPage}
        />
      ) : null}
      {curPage === 8 && isRailsCheckout ? <LogoutButton /> : null}
      {curPage === 7 && showResend ? (
        <div className="login-ca__subhead">
          Didn't get an email?{" "}
          <button
            className="login-ca__verify"
            type="button"
            onClick={() => {
              handleResend().then((resp) => {
                if (resp.message) {
                  setSuccess(resp.message);
                }
              });
            }}
          >
            Resend email
          </button>
        </div>
      ) : null}
      {curPage === 7 && success?.length > 0 ? (
        <div className="success">{success}</div>
      ) : null}
    </motion.div>
  );
};

export function EmailHeader({ number, setCurPage }) {
  return (
    <MainHeader>
      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 30px;
          `}
        >
          <BackButton setCurPage={setCurPage} />
          <h1
            className="capture-db__header-name"
            css={css`
              font-weight: 600;
              font-size: 20px;
              color: #2e2e2d;
              text-align: center;
              flex: 1;
              padding-right: 8px;
            `}
          >
            Enter your email
          </h1>
        </div>
      </div>
    </MainHeader>
  );
}

export default LoginCePageWrapperUserFlowMobile;
