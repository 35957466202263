/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState, memo } from "react";
import Scroll from "./InfiniteScrolling";
import FooterDesktop from "../2fa-user-flow/desktop/FooterDesktop";
import TwoBlackPhones from "../../../../assets/images/ambassador/TwoBlackPhones.svg";
import ListItem from "./ListItem";
import TopSection from "./TopSection";
import { ListYourRestaurantButton, LoginButton } from "./Buttons";
import { isMobileDevice } from "../2fa-user-flow";
import PhoneMenu from "./PhoneMenu";
import RestaurantDetails from "./RestaurantDetails";
import { getCookie } from "../../../lib/helpers";
import PropTypes from "prop-types";

function WelcomeScreen({
  setStep,
  user,
  loggedIn,
  step,
  setNearbyPrograms,
  nearbyPrograms,
  allActivePrograms,
  setAllActivePrograms,
  locationsCount,
  cloudinaryPath,
  ambassadorProgram,
}) {
  const [isRestaurantDetailsOpen, setIsRestaurantDetailsOpen] = useState(false);
  const [currentRestaurantDetails, setCurrentRestaurantDetails] =
    useState(null);
  const [, setWindowWidth] = useState(window.innerWidth);
  const userToken = getCookie("userToken") || null;

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const learnMore = () => {
    window.open("https://app.inkind.com/", "_blank", "noopener");
  };
  useEffect(() => {
    let headers = {};
    if (userToken) {
      headers = new Headers({ Authorization: userToken });
    }

    if (ambassadorProgram) {
      setIsRestaurantDetailsOpen(true);
      setCurrentRestaurantDetails(ambassadorProgram);
    }

    if (allActivePrograms === null) {
      fetch("/api/v5/ambassador_programs/all?per_page=4&page=1", {
        headers: headers,
      })
        .then((res) => res.json())
        .then((res) => {
          if (
            res &&
            res.ambassador_programs &&
            res.ambassador_programs.length
          ) {
            setAllActivePrograms(res.ambassador_programs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (nearbyPrograms === null) {
      fetch("api/v5/ambassador_programs/nearby", { headers: headers })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.ambassador_programs) {
            setNearbyPrograms(
              res.ambassador_programs.length > 4
                ? res.ambassador_programs.slice(0, 4)
                : res.ambassador_programs,
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const showRestaurantDetails = (e, program) => {
    if (isRestaurantDetailsOpen) {
      setIsRestaurantDetailsOpen(false);
      window.history.replaceState(
        null,
        "Discover Ambassador Programs | inKind",
        "/",
      );
      return;
    }
    e.preventDefault();
    e.stopPropagation(); // Prevents the default scroll behavior
    window.history.replaceState(
      null,
      program.brandName,
      "/details/" + program.brand_slug.replace(/_/g, "-"),
    );
    setIsRestaurantDetailsOpen(true);
    setCurrentRestaurantDetails(program);
  };
  return (
    <div className="welcome-screen">
      <div
        className="ambassador-web-discovery"
        css={css`
          position: relative;
        `}
      >
        {isRestaurantDetailsOpen && (
          <RestaurantDetails
            setIsRestaurantDetailsOpen={setIsRestaurantDetailsOpen}
            currentRestaurantDetails={currentRestaurantDetails}
            cloudinaryPath={cloudinaryPath}
            locationsCount={locationsCount}
          />
        )}
        {!isMobileDevice() && <ListYourRestaurantButton />}
        {loggedIn ? (
          <PhoneMenu
            step={step}
            setStep={setStep}
            profilePage={false}
            locationsCount={Number(locationsCount)}
          />
        ) : (
          <div className="not-logged-in">
            <PhoneMenu
              step={step}
              setStep={setStep}
              profilePage={false}
              locationsCount={Number(locationsCount)}
            />
            <LoginButton setStep={setStep} />
          </div>
        )}
        <TopSection
          heaaderText={"Welcome to inKind’s Ambassador program"}
          paraTextBold={"Earn money for your mentions!"}
          paraTextLight={
            "Share offers to amazing restaurants and earn a percentage of each referred diner’s bill amount in the form of inKind credit."
          }
        />
        <div className="infinite-scroll-content">
          {nearbyPrograms?.length > 0 ? (
            <>
              <h4
                css={css`
                  font-size: 20px;
                  font-weight: 500;
                  margin: 20px 0;
                `}
              >
                Nearby
              </h4>
              <div className="list-container">
                {nearbyPrograms.map((program, index) => {
                  return (
                    <div
                      onClick={(e) => showRestaurantDetails(e, program)}
                      key={index + 1}
                      className="list-item"
                      role="button"
                      tabIndex="0"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          showRestaurantDetails(e, program);
                        }
                      }}
                    >
                      <ListItem item={program} />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {allActivePrograms?.length > 0 ? (
            <>
              <h4
                css={css`
                  font-size: 20px;
                  font-weight: 500;
                  margin: 40px 0 20px 0;
                `}
              >
                Restaurants
              </h4>
              <div className="list-container">
                {allActivePrograms.map((program, index) => {
                  return (
                    <div
                      onClick={(e) => showRestaurantDetails(e, program)}
                      key={index + 1}
                      className="list-item"
                      role="button"
                      tabIndex="0"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          showRestaurantDetails(e, program);
                        }
                      }}
                    >
                      <ListItem item={program} />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          <div
            className="mid-section-content"
            css={css`
              display: flex;
              background: #fff;
              padding-top: 30px;
              margin: 20px 0;
              border-radius: 10px;
            `}
          >
            <div
              className=""
              css={css`
                flex: 5;
                margin-right: 20px;
              `}
            >
              <div className="info-section">
                <h2>Download the app</h2>
                <p>
                  You'll be able to view your earnings and spend your ambassador
                  bonuses at {locationsCount}+ restaurants on inKind.
                </p>
                <div
                  className="buttons"
                  css={css`
                    display: flex;
                  `}
                >
                  <button
                    type="button black-button"
                    onClick={learnMore}
                    className="learn-more"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div
              className=""
              css={css`
                flex: 7;
                overflow: hidden;
                max-width: 100%;
                margin-left: 40px;
              `}
            >
              <img src={TwoBlackPhones} alt="two-black-phones" />
            </div>
          </div>
          <Scroll
            parentClassName="parent-container"
            url={
              "/api/v5/ambassador_programs/all?per_page=|count|&page=|page_number|"
            }
            showRestaurantDetails={showRestaurantDetails}
          >
            <ListItem />
          </Scroll>
        </div>
      </div>
      <FooterDesktop />
    </div>
  );
}

export default memo(WelcomeScreen);

WelcomeScreen.propTypes = {
  setStep: PropTypes.func,
  setUser: PropTypes.func,
  loggedIn: PropTypes.bool,
  step: PropTypes.number,
  setNearbyPrograms: PropTypes.func,
  nearbyPrograms: PropTypes.array,
  allActivePrograms: PropTypes.array,
  setAllActivePrograms: PropTypes.func,
  locationsCount: PropTypes.any,
  cloudinaryPath: PropTypes.string,
  ambassadorProgram: PropTypes.object,
};
