import React, { useRef } from "react";
import textVerify from "../../../assets/images/icons/text-verify.png";
import { handlePost } from "./utilities";
import { formatPhoneNumber } from "@lib/helpers";
import useDeviceId from "@/events/useDeviceId";
import useSessionId from "@/events/useSessionId";

const VerifyAnotherWay = ({
  handleResult,
  phoneToken,
  verifyAction,
  phoneToText,
  isBslCheckout,
  isPartnerPortal,
  inviteToken,
}) => {
  const timeInterval = useRef(null);
  const getOs = () => {
    const userAgent = String(
      navigator.userAgent || navigator.vendor || window.opera,
    );
    if (/android\s\d+/i.test(userAgent)) return "android";
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "ios";
    return false;
  };
  const deviceId = useDeviceId();
  const sessionId = useSessionId();
  const checkVerified = async () => {
    const path = "/api/v5/verifications/phone/verify";
    const bodyJson = JSON.stringify({
      phone_token: phoneToken,
      verify_action: verifyAction,
      verification: "sms",
      invite_token: isPartnerPortal ? inviteToken : "",
    });
    const headers = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    const result = await handlePost(path, bodyJson, null, headers);
    if (result) {
      handleResult(result);
    }
  };

  const [os, setOs] = React.useState(null);

  React.useEffect(() => {
    if (sessionId) {
      timeInterval.current = setInterval(() => {
        checkVerified();
      }, 5000);
    }

    setOs(getOs());

    //clear interval on unmount
    return () => {
      clearInterval(timeInterval.current);
    };
  }, [sessionId]);

  return (
    <>
      <img src={textVerify} alt="Verify phone" className="login-ca__image" />
      <h3 className="login-ca__header login-ca__head center-header">
        <span className="login-ca__regular-font">Text the word</span> 'VERIFY'{" "}
        <span className="login-ca__regular-font">to</span>{" "}
        <div className="login-ca__phone_nummber">
          {formatPhoneNumber(phoneToText)}
        </div>
      </h3>
      <div className="login-ca__subhead text-page">
        Open your phone's texting platform and text us yourself to verify your
        phone number.
      </div>
      {os ? (
        <a
          className={`login-ca__button login-ca__continue-button actionable ${
            isBslCheckout ? "login-ca__bsl" : ""
          }`}
          href={`sms:${phoneToText}${os === "ios" ? "&" : "?"}body=VERIFY`}
        >
          Send text
        </a>
      ) : null}
    </>
  );
};

export default VerifyAnotherWay;
