/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Carousel1 from "../../../../../assets/images/ambassador/Carousel-1.png";
import Carousel2 from "../../../../../assets/images/ambassador/Carousel2.png";
import Carousel3 from "../../../../../assets/images/ambassador/Carousel3.png";
import Carousel4 from "../../../../../assets/images/ambassador/Carousel4.png";

export default function CarouselSectionDesktop() {
  function findNextAndPrev(idx) {
    const obj = {};
    let nextIdx = idx + 1;
    let prevIdx = idx - 1;
    for (let i = 0; i < 5; i++) {
      obj[nextIdx] = `next-${i + 1}`;
      obj[prevIdx] = `prev-${i + 1}`;
      nextIdx++;
      prevIdx--;
    }
    return obj;
  }

  const [slideIndex, setSlideIndex] = useState(2);
  const [arr, setArr] = useState([
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
  ]);
  const [prevAndNext, setPrevAndNext] = useState({
    1: "prev-1",
    3: "next-1",
    0: "prev-2",
    4: "next-2",
    "-1": "prev-3",
    5: "next-3",
    "-2": "prev-4",
    6: "next-4",
    "-3": "prev-5",
    7: "next-5",
  });

  const handleSlideChange = (index) => {
    if (index === 0) {
      const slider = document.querySelectorAll(".slider.animated")[0];
      slider.style.transition = "none";
      slider.style.transform = "translate3d(0)";
    } else if (index === 1) {
      const slider = document.querySelectorAll(".slider.animated")[0];
      slider.style.transition = "all .35s ease-in-out";
      slider.style.transform = "translate3d(0)";
    }
    setSlideIndex(index);
    setPrevAndNext(findNextAndPrev(index));
  };
  return (
    <div
      className="posts-container"
      css={css`
        max-width: 940px;
        margin: 0 auto;
        position: relative;
      `}
    >
      <Carousel
        centerMode={true}
        autoPlay={true}
        interval={2700}
        infiniteLoop={true}
        onChange={handleSlideChange}
        showIndicators={false}
        selectedItem={slideIndex}
        centerSlidePercentage={19.8}
      >
        {slideIndex > -1
          ? arr.map((carousel, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    index > slideIndex
                      ? `${String(prevAndNext[String(index)])} `
                      : " "
                  }
                            ${
                              index < slideIndex
                                ? String(prevAndNext[String(index)])
                                : ""
                            }`}
                >
                  <img src={carousel} alt={`Image ${index + 1}`} />
                </div>
              );
            })
          : null}
      </Carousel>
    </div>
  );
}
