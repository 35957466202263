import { useState, useEffect } from "react";
import useCookie from "@lib/use-cookie";
import { getUrlParams } from "./utils";

const COOKIE_SESSION_EXPIRY = 365; // Cookie expiration time (in days, approximately 30 minutes)
const SESSION_ID_KEY = "session_id";
const INACTIVITY_THRESHOLD = 30 * 60 * 1000;

const useSessionId = () => {
  const [sessionId, setSessionId] = useState<string | number>("");
  const [, setSessionCookie, getCookie] = useCookie(SESSION_ID_KEY);
  const [lastActivity, setLastActivity] = useState<number>(Date.now());
  let cookieSessionValue = getCookie();
  let parsedObj = getUrlParams(window.location.hash);

  // Generate a new session ID based on the current timestamp
  function generateSessionId() {
    return Date.now();
  }

  // Check if the session is expired based on inactivity
  function isSessionExpired(lastActivity: number) {
    const now = Date.now();
    return now - lastActivity > INACTIVITY_THRESHOLD;
  }

  // Retrieve or create a new session ID
  function getSessionId() {
    const expired = isSessionExpired(lastActivity);
    if (parsedObj && parsedObj.sessionId && !expired) {
      cookieSessionValue = parsedObj.sessionId;
      setSessionId(parsedObj.sessionId);
      setSessionCookie(parsedObj.sessionId, COOKIE_SESSION_EXPIRY);
    } else if (!cookieSessionValue || expired) {
      cookieSessionValue = generateSessionId();
      setSessionCookie(cookieSessionValue, COOKIE_SESSION_EXPIRY);
    }

    return cookieSessionValue;
  }

  useEffect(() => {
    // Function to handle user activity
    const handleUserActivity = () => {
      setLastActivity(Date.now());

      // Check and update session ID based on the updated activity
      if (parsedObj && parsedObj.sessionId && !isSessionExpired(lastActivity)) {
        cookieSessionValue = parsedObj.sessionId;
        setSessionId(parsedObj.sessionId);
        setSessionCookie(parsedObj.sessionId, COOKIE_SESSION_EXPIRY);
      } else if (!cookieSessionValue || isSessionExpired(lastActivity)) {
        const newSessionId = getSessionId(); // Get or create new session ID
        setSessionId(newSessionId);
      }
    };
    // Initialize session ID
    setSessionId(getSessionId());

    // Attach event listeners for user activity
    ["mousemove", "click", "keydown"].forEach((eventType) => {
      window.addEventListener(eventType, handleUserActivity);
    });

    // Clean up event listeners on component unmount
    return () => {
      ["mousemove", "click", "keydown"].forEach((eventType) => {
        window.removeEventListener(eventType, handleUserActivity);
      });
    };
  }, [lastActivity]); // Depend on lastActivity to re-trigger the effect on activity
  return sessionId;
};

export default useSessionId;
