/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useEffect, useContext } from "react";
import WelcomeScreen from "./WelcomeScreen";
import Success from "./Success";
import { ListContext } from "./";
import PropTypes from "prop-types";

export default function SuccessScreen({
  setStep,
  user,
  setNearbyPrograms,
  nearbyPrograms,
  allActivePrograms,
  setAllActivePrograms,
  cloudinaryPath,
  initialValues,
}) {
  const { setIsLoggedIn } = useContext(ListContext);
  useEffect(() => {
    setIsLoggedIn(true);
  }, [user]);
  return (
    <div className="signup-and-signin">
      <div className="left-section">
        <WelcomeScreen
          setNearbyPrograms={setNearbyPrograms}
          nearbyPrograms={nearbyPrograms}
          allActivePrograms={allActivePrograms}
          setAllActivePrograms={setAllActivePrograms}
          cloudinaryPath={cloudinaryPath}
          locationsCount={initialValues.locationsCount}
        />
      </div>
      <div className="right-section">
        <Success setStep={setStep} />
      </div>
    </div>
  );
}

SuccessScreen.propTypes = {
  setStep: PropTypes.func,
  user: PropTypes.object,
  setNearbyPrograms: PropTypes.func,
  nearbyPrograms: PropTypes.array,
  setAllActivePrograms: PropTypes.func,
  allActivePrograms: PropTypes.array,
  cloudinaryPath: PropTypes.string,
};
