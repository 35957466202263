/** @jsx jsx */
import { useEffect, useRef, useState } from "react";
import { css, jsx } from "@emotion/react";
import CongratsImg from "../../../../assets/images/ambassador/CongratsImage.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import InklndLogo from "../../../../assets/images/ambassador/inKindLogo.svg";

export default function Success({ disabled = false, brandName = "", setStep }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const sidebarRef = useRef(null);

  const finish = () => {
    setStep(1);
    window.location.href = "/";
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
      finish();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!isSidebarOpen) {
    return null;
  }

  return (
    <div
      ref={sidebarRef}
      className="error-fa-flow"
      css={css`
        padding: 20px;
      `}
    >
      <div>
        <button
          className="capture-db__btn-back"
          onClick={finish}
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 8px;
          `}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>
      <div className="inkind-logo">
        <img src={InklndLogo} className="inkind-logo-img" alt="InKind Logo" />
      </div>
      <div
        className="error-img"
        css={css`
          text-align: center;
        `}
      >
        <img src={CongratsImg} alt="Success" />
      </div>
      <div
        className="text-section"
        css={css`
          text-align: center;
        `}
      >
        <h2
          css={css`
            font-size: 31px;
            font-style: italic;
            font-weight: 400;
            font-family: Times;
          `}
        >
          Congrats!
        </h2>
        <p
          css={css`
            font-weight: 400;
            font-size: 14px;
            margin: 10px 0 40px 0;
          `}
        >
          You’re now all set to take advantage of all the inKind offers and
          discounts
        </p>
        <button
          onClick={finish}
          type="button"
          id="sign-up"
          className="black-button"
          css={css`
            background: #000;
            color: #fff;
            font-size: 15px;
            border-radius: 30px;
            width: calc(100% - 100px);
            position: absolute;
            left: 50px;
            bottom: 40px;
          `}
        >
          Finish
        </button>
      </div>
    </div>
  );
}
