import axios from "@lib/axios";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "../../../lib/helpers";

let userToken = getCookie("userToken") || null;

function setUserToken() {
  userToken = getCookie("userToken") || null;
}

export async function clearUserToken() {
  userToken = null;
}

export async function getLists() {
  if (!userToken) {
    setUserToken();
  }
  const auth = userToken ? "Bearer ".concat(userToken) : "";
  const axiosHeaders = {
    headers: {
      Authorization: auth,
    },
  };

  const url = "/api/v5/recipient_lists";
  return await axios.get(url, axiosHeaders);
}

export async function getLocations() {
  const url = "/api/v5/map";
  return await axios.get(url);
}

export async function saveNewList(name, id = null) {
  if (!userToken) {
    setUserToken();
  }
  const auth = userToken ? "Bearer ".concat(userToken) : "";
  const axiosHeaders = {
    headers: {
      Authorization: auth,
    },
  };
  let bodyFormData = new FormData();
  !!name ? bodyFormData.append("name", name) : (bodyFormData = {});
  const url = !id ? `/api/v5/recipient_lists` : `/api/v5/recipient_lists/${id}`;
  return await axios.post(url, bodyFormData, axiosHeaders);
}

export async function saveRecipient(
  listId,
  firstname,
  lastname,
  email,

  id = null,
) {
  if (!userToken) {
    setUserToken();
  }
  const auth = userToken ? "Bearer ".concat(userToken) : "";
  const axiosHeaders = {
    headers: {
      Authorization: auth,
    },
  };
  const bodyFormData = new FormData();
  bodyFormData.append("first_name", firstname);
  bodyFormData.append("email", email);
  lastname && bodyFormData.append("last_name", lastname);

  const url = id
    ? `/api/v5/recipient_lists/${listId}/recipient_list_contacts/${id}`
    : `/api/v5/recipient_lists/${listId}/recipient_list_contacts`;
  return await axios.post(url, bodyFormData, axiosHeaders);
}
export async function deleteRecipient(listId, id) {
  if (!userToken) {
    setUserToken();
  }
  const auth = userToken ? "Bearer ".concat(userToken) : "";
  const axiosHeaders = {
    headers: {
      Authorization: auth,
    },
  };

  const url = `/api/v5/recipient_lists/${listId}/recipient_list_contacts/${id}`;
  return await axios.delete(url, axiosHeaders);
}

export async function uploadCSV(csv) {
  if (!userToken) {
    setUserToken();
  }
  const auth = userToken ? "Bearer ".concat(userToken) : "";
  const axiosHeaders = {
    headers: {
      Authorization: auth,
      "Content-Type": "multipart/form-data",
    },
  };
  const bodyFormData = new FormData();
  bodyFormData.append("csv_file", csv);
  const url = `/api/v5/recipient_lists/upload`;
  return await axios.post(url, bodyFormData, axiosHeaders);
}

function dynamicSort(property) {
  if (!userToken) {
    setUserToken();
  }
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export const savedListApi = createApi({
  reducerPath: "savedListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v5/",
    //change to inKind
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(getState().user.user).token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["BulkGift", "Map", "Redemptions", "SelectedList", "UserLists"],
  endpoints: (builder) => ({
    getList: builder.query({
      query: (id) => `recipient_lists/${id}`,
      transformResponse: (response, meta, arg) =>
        response.recipient_list.contacts.sort(dynamicSort("id")),
      providesTags: ["SelectedList"],
    }),

    uploadCSV: builder.mutation({
      query: (body) => ({
        url: `recipient_lists/upload`,
        method: "POST",
        body,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["UserLists"],
    }),

    getLocations: builder.query({
      query: () => "map/",
      providesTags: ["Map"],
    }),

    getBulkGifts: builder.query({
      query: () => "bulk_gifts/",
      transformResponse: (response, meta, arg) => response.bulk_gifts.reverse(),
      providesTags: ["BulkGift"],
    }),
    getSingleBulkGift: builder.query({
      query: (id) => `bulk_gifts/${id}`,
      // transformResponse: (response, meta, arg) => response.recipients.reverse(),
      providesTags: (result, error, arg) =>
        result.recipients
          ? [
              ...result.recipients.map(({ id }) => ({
                type: "Redemptions",
                id,
              })),
              "Redemptions",
            ]
          : ["Redemptions"],
    }),
    saveListRecipient: builder.mutation({
      query: (body) => ({
        url: body.id
          ? `recipient_lists/${body.list_id}/recipient_list_contacts/${body.id}`
          : `recipient_lists/${body.list_id}/recipient_list_contacts`,
        method: "POST",
        body,
      }),
      // invalidatesTags: ["SelectedList"],
    }),
    editRecipient: builder.mutation({
      query: (body) => ({
        url: `bulk_gift_recipients/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Redemptions", id: arg.id },
      ],
    }),
    sendBulkGift: builder.mutation({
      query: (body) => ({
        url: "bulk_gifts/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["BulkGift"],
    }),
    saveList: builder.mutation({
      query: (body) => ({
        url: !body.id ? `recipient_lists` : `recipient_lists/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["UserLists"],
    }),
    deleteList: builder.mutation({
      query: (body) => ({
        url: `recipient_lists/${body.id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["UserLists", "SelectedList"],
    }),
    getUserLists: builder.query({
      query: () => `recipient_lists`,
      transformResponse: (response, meta, arg) => response.recipient_lists,
      providesTags: ["UserLists"],
    }),
  }),
});
