import React from "react";

export const Button = ({ children, onClick, buttonClasses, ...props }) => {
  return (
    <>
      <button
        data-testid="button-element"
        className={buttonClasses || ""}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    </>
  );
};
