import React, { useState } from "react";

const getItem = (key) => {
  return document.cookie.split("; ").reduce((total, currentCookie) => {
    const item = currentCookie.split("=");
    const storedKey = item[0];
    const storedValue = item[1];

    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, "");
};

const setItem = (key, value, numberOfDays) => {
  const now = new Date();

  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + (numberOfDays || 0) * 60 * 60 * 24 * 1000);

  const hostParts = document.location.hostname.split(".");
  const isLocal = hostParts[hostParts.length - 1] === "localhost";
  const cookieDomain =
    "." +
    (isLocal
      ? hostParts[hostParts.length - 1]
      : (hostParts.length > 2 ? hostParts.slice(1) : hostParts).join("."));
  document.cookie = `${key}=${value};expires=${now.toUTCString()};path=/${
    key === "ik_pcoo" ? ";SameSite=Strict" : ""
  }${isLocal ? ";Secure" : `;Secure;domain=${cookieDomain}`}`;
};

/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
const useCookie = (key, defaultValue) => {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value, numberOfDays) => {
    setCookie(value);
    setItem(key, value, numberOfDays);
  };
  return [cookie, updateCookie, getCookie];
};

export default useCookie;
