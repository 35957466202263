import axios from "@lib/axios";

export async function dnsCheck(email) {
  try {
    await axios.post("/api/v2/users/valid_domain.json", {
      email,
    });
    return true;
  } catch {
    return false;
  }
}
