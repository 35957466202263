import React from "react";
import { Checkbox2Props } from "../../helpers/types";

const Checkbox = ({
  onBlur,
  values,
  name,
  setFieldValue,
  id,
}: Checkbox2Props) => {
  const [isChecked, setIsChecked] = React.useState(values[name]);

  React.useEffect(() => {
    setFieldValue(name, isChecked);
  }, [isChecked]);

  return (
    <input
      type="checkbox"
      id={id}
      className="login-ca__checkbox"
      name={name}
      checked={values[name]}
      onBlur={onBlur}
      onChange={() => setIsChecked(!isChecked)}
    />
  );
};

export default Checkbox;
