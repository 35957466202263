import React, { useState, useEffect } from "react";
import AdminModal from "../../ui/molecules/AdminModal.jsx";
import AdminModalHeader from "../../ui/molecules/AdminModalHeader.jsx";
import VisibilityItemRow from "./VisibilityItemRow.jsx";
import { Button } from "../../ui/atoms/Button/index.jsx";
import { InKindNetworkError } from "../../helpers/InKindNetworkError";

const App = ({ location }) => {
  const [visibilityLevels, setVisibilityLevels] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const PATH = "/admin/menus/" + location.id;

  const handleGetData = async () => {
    try {
      const response = await fetch(PATH);
      if (!response.ok) {
        const errorMessage = await response.text();
        throw JSON.parse(errorMessage).errors[0];
      } else {
        const data = await response.json();
        if (typeof data?.visibility_levels === "string") {
          setVisibilityLevels(JSON.parse(data?.visibility_levels));
        } else {
          setVisibilityLevels(data?.visibility_levels);
        }
      }
    } catch (error) {
      document
        .querySelectorAll(".handle-menu-visibility-container")
        .forEach((button) => {
          button.classList.add("hide-button-permanent");
        });
      return false;
    }
  };

  const handlePutData = async () => {
    setError(false);
    setSuccess("Saving...");
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ visibility_levels: visibilityLevels }),
    };

    try {
      const response = await fetch(PATH, requestOptions);
      if (!response.ok) {
        const errorMessage = await response.text();
        const parsedError = JSON.parse(errorMessage);
        if (parsedError.error) {
          throw parsedError.error;
        } else if (parsedError.errors) {
          throw parsedError.errors[0];
        }
      } else {
        setSuccess("Menu items saved successfully.");
      }
    } catch (error) {
      let err;
      if (JSON.stringify(error) === "{}") {
        err = { message: "An unknown error occurred." };
      } else {
        err = InKindNetworkError(error);
      }
      setSuccess(false);
      setError(err.message);
      return false;
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.replace(/^\w/, (c) => c.toUpperCase());
  };

  const handleValueChange = (val, menuKey) => {
    const newVis = [];
    if (val?.length > 0) {
      val.forEach((vis) => {
        newVis.push(vis.value);
      });
    }
    const newVisibilityLevels = visibilityLevels;
    newVisibilityLevels.menu[menuKey].visibility_level = newVis;
    setVisibilityLevels(newVisibilityLevels);
  };

  const handleClose = () => {
    setSuccess(false);
    setError(false);
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <AdminModal>
      <AdminModalHeader
        text="Menu visibility levels"
        closeBehavior={handleClose}
      />
      <table className="menu-visibility__table">
        <thead className="menu-visibility__thead">
          <tr className="menu-visibility__tr">
            <th className="menu-visibility__td">Category</th>
            <th className="menu-visibility__td">Visibility</th>
          </tr>
        </thead>
        <tbody className="menu-visibility__tbody">
          {visibilityLevels?.menu
            ? Object.keys(visibilityLevels.menu).map((menuKey, index) => {
                const itemName = visibilityLevels.menu[menuKey].name;
                const optionsSelectedFormatted = [];
                visibilityLevels.menu[menuKey].visibility_level.forEach(
                  (item) => {
                    optionsSelectedFormatted.push({
                      value: item,
                      label: capitalizeFirstLetter(item),
                    });
                  },
                );
                return (
                  <VisibilityItemRow
                    itemName={itemName}
                    menuKey={menuKey}
                    index={index}
                    optionsSelectedFormatted={optionsSelectedFormatted}
                    handleValueChange={handleValueChange}
                    key={menuKey}
                  />
                );
              })
            : null}
        </tbody>
      </table>
      <div className="menu-visibility__btn-container">
        <Button onClick={handlePutData} buttonClasses={"btn btn-primary"}>
          Submit
        </Button>
      </div>
      {success ? (
        <div className="menu-visibility__success">{success}</div>
      ) : null}
      {error ? <div className="menu-visibility__error">{error}</div> : null}
    </AdminModal>
  );
};

export default App;
