import React from "react";
import { Switch } from "@headlessui/react";

const PreviewSend = ({
  isActivityLog,
  logMessage,
  setLogMessage,
  handleSend,
  toggleValue,
  setToggleValue,
  isSending,
  showAutoApprove,
  noReviewers,
  noApprovers,
  approveDatePast,
  handlePut,
  handleUnload,
  isLoadingBrands,
  brandSlug,
}) => {
  React.useEffect(() => {
    //we don't want the form to submit on activity log enter because it's confusing
    document.getElementById("activity-input").onkeypress = function (e) {
      const key = e.charCode || e.keyCode || 0;
      if (key == 13) {
        e.preventDefault();
      }
    };
  }, []);

  return (
    <>
      <div
        className="activity-history-container"
        style={{ borderTop: !!isActivityLog ? "0px" : "1px solid #e6eaee" }}
      >
        <input
          className="activity-history-input"
          id="activity-input"
          aria-label="Type a message"
          placeholder="Type a message..."
          value={logMessage}
          onChange={(e) => {
            setLogMessage(e.target.value);
          }}
        />
      </div>
      {!!showAutoApprove && (
        <div className="activity-history-container">
          <div className="activity-history-note">
            <span className="note-text">Note:&nbsp;</span> As the revision
            deadline has passed, your next preview sent to the customer will
            auto-approve this brand.
          </div>
        </div>
      )}
      <div
        className="activity-history-container"
        style={{ borderBottom: !!isActivityLog ? "1px solid #e6eaee" : "0px" }}
      >
        <div className="activity-toggle-container">
          <div className="switch-holder">
            <Switch
              checked={toggleValue}
              value={toggleValue}
              onChange={(e) => setToggleValue(e)}
              name="override-creative"
              className={`${
                !!toggleValue ? "enabled" : "disabled"
              } blue-switch`}
            >
              <span className="sr-only">Override existing creative?</span>
              <span
                className={`${!!toggleValue ? "on" : "off"} switch-handle`}
              />
            </Switch>
          </div>
          <span>
            {!!isActivityLog
              ? "Attach updated preview"
              : "Force resend to all who have not approved"}
          </span>
        </div>

        <div className="activity-history-options">
          {!!isActivityLog ? (
            <a
              className="internal-button"
              href={`/admin/brand_dashboard/brands/${brandSlug}/ownerships/edit`}
              target="blank"
            >
              Manage reviewers
            </a>
          ) : null}
          {!!isActivityLog ? (
            <div
              className="internal-button"
              onClick={() =>
                !!isActivityLog
                  ? Promise.all([handlePut(null, true)]).then(() => {
                      handleSend(true);
                    })
                  : Promise.all([handleUnload()]).then((response) => {
                      if (response[0] === true || response === true) {
                        handleSend(true);
                      }
                    })
              }
              disabled={
                (logMessage?.length === 0 && toggleValue === false) ||
                !!isSending ||
                !!isLoadingBrands
              }
            >
              {!!toggleValue ? "Send to self" : "Log internal note"}
            </div>
          ) : null}
          <div
            className="send-button"
            onClick={() =>
              !!isActivityLog
                ? Promise.all([handlePut(null, true)]).then(() => {
                    handleSend(false);
                  })
                : Promise.all([handleUnload()]).then((response) => {
                    if (response[0] === true || response === true) {
                      handleSend(false);
                    }
                  })
            }
            disabled={
              (logMessage?.length === 0 && toggleValue === false) ||
              !!isSending ||
              !!noReviewers ||
              !!approveDatePast ||
              !!isLoadingBrands
            }
          >
            {!!isActivityLog ? "Send to client" : "Bulk send to partners"}
          </div>
        </div>
      </div>
      <div
        className="approvers-warning"
        style={{ display: !!noApprovers || !!noReviewers ? "block" : "none" }}
      >
        Warning: this brand has no {!!noReviewers ? "reviewers" : "approvers"}{" "}
        configured.
      </div>
      <div
        className="past-approve-date-warning"
        style={{ display: !!approveDatePast ? "block" : "none" }}
      >
        Approval Deadline has already passed. You can send to individual brands
        but bulk review is not enabled after Approval Deadline has passed.
      </div>
    </>
  );
};

export default PreviewSend;
