import React from "react";

export default function GetAccessSection() {
  return (
    <div className="get-access-section">
      <h2>Share. Earn. Dine.</h2>
      <p>
        Use your credits earned at thousands of top restaurants. Discover more
        at{" "}
        <a
          href={"https://inkind.com"}
          target={"blank"}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          inkind.com
        </a>
        .
      </p>
    </div>
  );
}
