import { createSlice } from "@reduxjs/toolkit";

//Initial state

function today() {
  let date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  let datestr = date.toISOString().substring(0, 10);
  return datestr;
}

const initialState = {
  recipientName: "",
  recipientEmail: "",
  deliveryDate: today(),
  giftMessage: "",
  sendersName: "",
};

// Create reducers/actions

export const recipSlice = createSlice({
  name: "recip",
  initialState,
  reducers: {
    setRecipName: (state, action) => {
      state.recipientName = action.payload;
    },
    setRecipEmail: (state, action) => {
      state.recipientEmail = action.payload;
    },
    setDeliveryDate: (state, action) => {
      state.deliveryDate = action.payload;
    },
    setGiftMessage: (state, action) => {
      state.giftMessage = action.payload;
    },
    setSendersName: (state, action) => {
      state.sendersName = action.payload;
    },
  },
});

// Export actions

export const {
  setRecipName,
  setRecipEmail,
  setDeliveryDate,
  setGiftMessage,
  setSendersName,
} = recipSlice.actions;

export default recipSlice.reducer;
