/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import LinkGenerated from "./LinkGenerated";
import { getCookie } from "../../../lib/helpers";

export default function OfferModal({ offerData, setIsModalOpen, item }) {
  const sideBarRef = useRef(null);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    // Check if 'ambassadorModal' cookie is already set to true
    const isCookieSet =
      getCookie(
        `ambassadorModalClaim${offerData?.welcome_offer?.claim_details?.offer_claim_id}`,
      ) === "true";

    if (isCookieSet) {
      setShowModal(false); // Don't show the modal if cookie is set
    } else {
      // Set the 'ambassadorModal' cookie to true with expiration of 5 days
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 5);
      document.cookie = `ambassadorModalClaim${offerData?.welcome_offer?.claim_details?.offer_claim_id}=true; expires=${expirationDate.toUTCString()}; path=/`;
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (sideBarRef.current && !sideBarRef.current.contains(e.target)) {
      setIsModalOpen(false);
    }
  };

  // Only render the modal if showModal is true
  if (!showModal) {
    setIsModalOpen(false);
  }

  return (
    <>
      <div ref={sideBarRef} className="modal offer-modal-ambassador">
        <button onClick={() => setIsModalOpen(false)} className="close-btn">
          &times;
        </button>
        <h1 className="title">Welcome offer added to your inKind account</h1>
        <p className="subtitle">
          Here’s a little something to get you started. Redeem at the restaurant
          with the inKind app.
        </p>
        <div className="offer-card">
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              padding: 5px;
              margin-right: 15px;
              width: 56px;
              height: 60px;
              background-size: cover;
              background-image: url(${item?.brand_hero_image_url ||
              item?.brandHeroImageUrl});
            `}
          >
            <div className="brand-logo-wrapper">
              <img
                src={item?.brand_dark_logo || item?.brandDarkLogo}
                alt={item?.brand_name}
                className="offer-image"
              />
            </div>
          </div>
          <div className="offer-details">
            <h2>{offerData?.welcome_offer?.brand?.name || ""}</h2>
            <p>{` $${offerData?.welcome_offer?.amount || "-"} off $${offerData?.welcome_offer?.minimum_spend_amount || "+"}+`}</p>
          </div>
        </div>

        <div className="share-section">
          <h3 className="share-link-text">Share Your Link:</h3>
          <LinkGenerated
            offerData={offerData}
            backgroundColor={"#F6F6F6"}
            fontFamily={"Inter"}
          />
        </div>

        <div className="actions">
          <button className="done-btn" onClick={() => setIsModalOpen(false)}>
            Done
          </button>
          <button
            className="view-btn"
            onClick={() => {
              window.open(
                "https://app.inkind.com/rewards",
                "_blank",
                "noopener",
              );
            }}
          >
            View in App
          </button>
        </div>
      </div>
    </>
  );
}

OfferModal.propTypes = {
  item: PropTypes.object,
  offerData: PropTypes.object,
  setIsModalOpen: PropTypes.func,
};
