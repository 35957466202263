import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  amountPaid: 0,
  amountReceived: 0,
};

// Create reducers/actions

export const massResponseSlice = createSlice({
  name: "massResponse",
  initialState,
  reducers: {
    setAmountPaid: (state, action) => {
      state.amountPaid = action.payload;
    },
    setAmountReceived: (state, action) => {
      state.amountReceived = action.payload;
    },
  },
});

// Export actions

export const { setAmountPaid, setAmountReceived } = massResponseSlice.actions;

export default massResponseSlice.reducer;
