export const calculateFontColorFor = (backgroundColor) => {
  if (!backgroundColor || backgroundColor.length < 1) {
    return "";
  }
  var hex = backgroundColor.replace(/#/, "");
  var r = parseInt(hex.slice(0, 2), 16);
  var g = parseInt(hex.slice(2, 4), 16);
  var b = parseInt(hex.slice(4, 6), 16);
  var color = (299 * r + 587 * g + 114 * b) / 1000;
  if (color >= 145) {
    return "#000000";
  } else {
    return "#FFFFFF";
  }
};
