import { configureStore } from "@reduxjs/toolkit";
import navReducer from "./slices/navSlice";
import userReducer from "./slices/userSlice";
import companyReducer from "./slices/companySlice";
import massResponseReducer from "./slices/massResponseSlice";
import recipReducer from "./slices/recipSlice";
import massGiftReducer from "./slices/massGiftSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { savedListApi } from "../services/savedList";

export const store = configureStore({
  reducer: {
    nav: navReducer,
    user: userReducer,
    company: companyReducer,
    recip: recipReducer,
    massGift: massGiftReducer,
    massResponse: massResponseReducer,
    [savedListApi.reducerPath]: savedListApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(savedListApi.middleware),
});

export type IRootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
