/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Header from "../Header";
import BgImage from "../../../../../assets/images/ambassador/background-2fa-desktop.svg";
import { calculateFontColorFor } from "../../../../lib/calculateFontColor";
import { PhoneIcon, GenerateLinkIcon, ShareNSaveIcon } from "../SVGs";

export default function SignupDesktop({ step, setStep, initialValues }) {
  const signUp = (e) => {
    e.preventDefault();
    setStep(2);
  };

  return (
    <div
      className="signup-desktop"
      css={css`
        min-height: 100vh;
        position: relative;
        background-image: url(${initialValues?.brandHeroImageUrl ||
        BgImage}) !important;
      `}
    >
      <div
        className={`${step >= 2 ? "selected-container" : "container"} pop-up`}
        style={{ backgroundColor: initialValues?.backgroundColor || "#000" }}
      >
        <header>
          <Header initialValues={initialValues} replaceLogo={true} />
          <h1
            className="main-header-data"
            dangerouslySetInnerHTML={{ __html: initialValues?.header || "" }}
            style={{
              color: calculateFontColorFor(initialValues?.backgroundColor),
            }}
          />
          <p
            dangerouslySetInnerHTML={{ __html: initialValues?.body || "" }}
            style={{
              color: calculateFontColorFor(initialValues?.backgroundColor),
            }}
          />
          <style>{` header::after,.header::after { background-color: ${initialValues?.accentColor}; } `}</style>
        </header>
        <div
          className="icons-and-content"
          css={css`
            margin-top: 45px;
            margin-bottom: 45px;
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <div
            className="content"
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: 40px;
              justify-content: flex-start;
              width: 100%;
              max-width: 365px;
            `}
          >
            <div
              className="icon-svg"
              css={css`
                width: 44px;
                height: 44px;
                margin-right: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <span>
                <PhoneIcon color={initialValues?.accentColor} />
              </span>
            </div>
            <div
              className="text-content"
              css={css`
                flex: 1;
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <p
                css={css`
                  font-size: 15px;
                  margin-bottom: 5px;
                  font-weight: 600;
                  color: ${calculateFontColorFor(
                    initialValues?.backgroundColor,
                  )};
                `}
              >
                Sign up
              </p>
              <p
                css={css`
                  font-size: 14px;
                  font-weight: 400;
                  color: ${calculateFontColorFor(
                    initialValues?.backgroundColor,
                  )};
                  opacity: 50%;
                `}
              >
                Get your unique ${initialValues.referralAmount} off $
                {initialValues.minimumSpend}+ link to share
              </p>
            </div>
          </div>
          <div
            className="content"
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: 40px;
              justify-content: flex-start;
              width: 100%;
              max-width: 365px;
            `}
          >
            <div
              className="icon-svg"
              css={css`
                width: 44px;
                height: 44px;
                margin-right: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <span>
                <GenerateLinkIcon color={initialValues?.accentColor} />
              </span>
            </div>
            <div
              className="text-content"
              css={css`
                flex: 1;
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <p
                css={css`
                  font-size: 15px;
                  margin-bottom: 5px;
                  font-weight: 600;
                  color: ${calculateFontColorFor(
                    initialValues?.backgroundColor,
                  )};
                `}
              >
                Share your referral link
              </p>
              <p
                css={css`
                  font-size: 14px;
                  font-weight: 400;
                  color: ${calculateFontColorFor(
                    initialValues?.backgroundColor,
                  )};
                  opacity: 50%;
                `}
              >
                Post it in your story, on reviews, anywhere!
              </p>
            </div>
          </div>
          <div
            className="content"
            css={css`
              display: flex;
              align-items: center;
              color: ${calculateFontColorFor(initialValues?.backgroundColor)};
              justify-content: flex-start;
              width: 100%;
              max-width: 365px;
            `}
          >
            <div
              className="icon-svg"
              css={css`
                width: 44px;
                height: 44px;
                margin-right: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
              style={{
                backgroundColor: "transparent",
                width: "44px",
                height: "44px",
              }}
            >
              <span>
                <ShareNSaveIcon color={initialValues?.accentColor} />
              </span>
            </div>
            <div
              className="text-content"
              css={css`
                flex: 1;
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <p
                css={css`
                  font-size: 15px;
                  margin-bottom: 5px;
                  font-weight: 600;
                  color: ${calculateFontColorFor(
                    initialValues?.backgroundColor,
                  )};
                `}
              >
                They save, you earn!
              </p>
              <p
                css={css`
                  font-size: 14px;
                  font-weight: 400;
                  color: ${calculateFontColorFor(
                    initialValues?.backgroundColor,
                  )};
                  opacity: 50%;
                `}
              >
                Enjoy your earnings at {initialValues.locationsCount}+
                Restaurants
              </p>
            </div>
          </div>
        </div>
        <div
          className="button-section"
          css={css`
            text-align: center;
          `}
        >
          <button
            onClick={signUp}
            type="button"
            id="sign-up"
            style={{ backgroundColor: initialValues?.accentColor || "#000" }}
            css={css`
              color: ${calculateFontColorFor(initialValues?.accentColor)};
              font-size: 15px;
              border-radius: 30px;
              padding: 10px 30px;
              background-color: ${initialValues.accentColor};

              p {
                margin: 0;
              }
            `}
            dangerouslySetInnerHTML={{ __html: initialValues?.button || "" }}
          />
        </div>
      </div>
    </div>
  );
}
