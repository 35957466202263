import React from "react";
import InputLayout from "../../ui/molecules/InputLayout.tsx";
import Checkbox from "../../ui/molecules/Checkbox.tsx";
import { useFormikContext } from "formik";
import PartnerPortalInputWrapper from "../partner-portal/molecules/PartnerPortalInputWrapper.tsx";
import ConditionalWrapper from "../../ui/atoms/ConditionalWrapper/ConditionalWrapper.jsx";

const CreateAccount = ({ isPartnerPortal }) => {
  const { values, handleBlur, handleChange, setFieldValue, errors } =
    useFormikContext();

  return (
    <>
      <ConditionalWrapper
        condition={isPartnerPortal}
        wrapper1={(children) => (
          <InputLayout label="Email" error={errors.email} showLabel={false}>
            {children}
          </InputLayout>
        )}
        wrapper2={(children) => (
          <PartnerPortalInputWrapper
            label="Email"
            name={"email"}
            hideLabel={true}
          >
            {children}
          </PartnerPortalInputWrapper>
        )}
      >
        <div
          type="email"
          name="email"
          aria-label="email"
          className="floating-label-input login-ca__disabled-field"
        >
          {values.email}
        </div>
      </ConditionalWrapper>
      <div className="two-col first-last">
        <ConditionalWrapper
          condition={isPartnerPortal}
          wrapper1={(children) => (
            <InputLayout label="First Name" error={errors.firstName}>
              {children}
            </InputLayout>
          )}
          wrapper2={(children) => (
            <PartnerPortalInputWrapper label="First Name" name={"firstName"}>
              {children}
            </PartnerPortalInputWrapper>
          )}
        >
          <input
            id="first name"
            name="firstName"
            type="text"
            aria-label="first name"
            pattern="^[A-Za-z][A-Za-z-'\s]+$"
            title="First name may not include numbers or special characters."
            placeholder=" "
            value={values.firstName}
            className="floating-label-input"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="given-name"
          />
        </ConditionalWrapper>

        <ConditionalWrapper
          condition={isPartnerPortal}
          wrapper1={(children) => (
            <InputLayout label="Last Name" error={errors.lastName}>
              {children}
            </InputLayout>
          )}
          wrapper2={(children) => (
            <PartnerPortalInputWrapper label="Last Name" name={"lastName"}>
              {children}
            </PartnerPortalInputWrapper>
          )}
        >
          <input
            id="last name"
            name="lastName"
            type="text"
            pattern="^[A-Za-z][A-Za-z-'\s]+$"
            title="Last name may not include numbers or special characters."
            aria-label="last name"
            placeholder=" "
            value={values.lastName}
            className="floating-label-input"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="family-name"
          />
        </ConditionalWrapper>
      </div>
      {isPartnerPortal ? (
        <PartnerPortalInputWrapper label="Title" name="title">
          <input
            id="title"
            name="title"
            type="text"
            pattern="^[A-Za-z][A-Za-z-'\s]+$"
            aria-label="title"
            placeholder=" "
            value={values.title}
            className="floating-label-input"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </PartnerPortalInputWrapper>
      ) : null}
      <ConditionalWrapper
        condition={isPartnerPortal}
        wrapper1={(children) => (
          <InputLayout label="Password" error={errors.password}>
            {children}
          </InputLayout>
        )}
        wrapper2={(children) => (
          <PartnerPortalInputWrapper label="Password" name={"password"}>
            {children}
          </PartnerPortalInputWrapper>
        )}
      >
        <input
          type="password"
          name="password"
          aria-label="password"
          placeholder=" "
          className="floating-label-input"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="new-password"
        />
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={isPartnerPortal}
        wrapper1={(children) => (
          <InputLayout label="Confirm Password" error={errors.confirmPassword}>
            {children}
          </InputLayout>
        )}
        wrapper2={(children) => (
          <PartnerPortalInputWrapper
            label="Confirm Password"
            name={"confirmPassword"}
          >
            {children}
          </PartnerPortalInputWrapper>
        )}
      >
        <input
          type="password"
          name="confirmPassword"
          aria-label="confirmPassword"
          placeholder=" "
          className="floating-label-input"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="new-password"
        />
      </ConditionalWrapper>
      <InputLayout overrideClass={"email"} error={errors.agreeToTerms}>
        <div className="login-ca__check-box" style={{ alignItems: "start" }}>
          <Checkbox
            id="agreeToTerms"
            type="checkbox"
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            className={`login-ca__check-box`}
            name="agreeToTerms"
          />
          <label htmlFor="agreeToTerms">
            I agree to inKind’s{" "}
            <a
              href={"https://inkind.com/terms"}
              className="login-ca__terms-link"
              target="_blank"
            >
              Terms of Use
            </a>
            ,{" "}
            <a
              href={"https://inkind.com/privacy"}
              className="login-ca__terms-link"
              target="_blank"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href={"https://inkind.com/notice-financial-incentive"}
              className="login-ca__terms-link"
              target="_blank"
            >
              Notice of Financial Incentive
            </a>
            {/*
             */}
            , which includes receiving marketing communications from inKind.
          </label>
        </div>
      </InputLayout>
    </>
  );
};

export default CreateAccount;
