import React from "react";
import { Listbox } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import filter from "../../../assets/images/map_v2/filter.svg";

const MobileSelectMenu = ({
  selectedMoods,
  setSelectedMoods,
  selectedCuisines,
  setSelectedCuisines,
  moods,
  cuisines,
  colorMode,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const isSelected = (val, type) => {
    let useList = type === "cuisine" ? selectedCuisines : selectedMoods;
    return useList?.name === val.name ? true : false;
  };

  const handleSelectedItems = (val) => {
    let selectedItemsUpdated;
    let isCuisine = document
      .getElementsByClassName(val.name.replace(/[^A-Za-z0-9]/g, ""))[0]
      .classList.contains("cuisine");
    let setSelectedItems = !!isCuisine ? setSelectedCuisines : setSelectedMoods;
    let SetunselectedItems = !isCuisine
      ? setSelectedCuisines
      : setSelectedMoods;
    let type = !!isCuisine ? "cuisine" : "mood";
    if (!isSelected(val, type)) {
      selectedItemsUpdated = val;
    } else {
      selectedItemsUpdated = null;
    }
    setSelectedItems(selectedItemsUpdated);
    SetunselectedItems(null);
    setIsOpen(false);
  };

  return (
    <>
      <Listbox
        as="div"
        value={selectedCuisines}
        onChange={(val) => handleSelectedItems(val)}
        className="mobile-select-container"
        open={isOpen}
      >
        <a onClick={() => setIsOpen(!isOpen)} className="open-menu-button">
          <Listbox.Button
            as="button"
            className={`map-filter-button mobile-filter-button ${
              (!!selectedCuisines || !!selectedMoods) &&
              "mobile-selected-filter"
            } ${colorMode === "black" ? "map-filter-button-black" : ""}`}
          >
            <img src={filter} />{" "}
          </Listbox.Button>
        </a>
        <AnimatePresence>
          {!!isOpen && (
            <motion.div
              key="mobileSelectMenu"
              className="menu-items-container"
              id="mobile-list-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Listbox.Options
                as="ul"
                className={
                  colorMode === "black"
                    ? "menu-items mobile-menu-items black-menu-items"
                    : "menu-items mobile-menu-items"
                }
                static
              >
                <div
                  className="menu-header mobile-menu-header"
                  onClick={() => setIsOpen(false)}
                >
                  <div className="menu-title mobile-menu-title">Cuisines</div>
                  <a
                    className="clear"
                    onClick={() => {
                      setSelectedCuisines(null);
                      setSelectedMoods(null);
                    }}
                  >
                    Clear
                  </a>
                </div>
                <div className="dummy-menu-header"></div>

                {cuisines.map((cuisine) => {
                  const selected = isSelected(cuisine, "cuisine");
                  return (
                    <Listbox.Option
                      as="li"
                      key={cuisine.id}
                      value={cuisine}
                      className={`${cuisine.name.replace(
                        /[^A-Za-z0-9]/g,
                        "",
                      )} cuisine`}
                    >
                      <>
                        <div
                          className={
                            !!selected ? "selected-check" : "unselected-check"
                          }
                        ></div>
                        <span>{cuisine.name}</span>
                      </>
                    </Listbox.Option>
                  );
                })}
                {/*<div
                className="menu-header mobile-menu-header"
                onClick={() => setIsOpen(false)}
              >
                <div className="menu-title mobile-menu-title">Moods</div>
                <a
                  className="clear"
                  onClick={() => {
                    setSelectedCuisines(null);
                    setSelectedMoods(null);
                  }}
                >
                  Clear
                </a>
              </div>
                <div className="dummy-menu-header"></div>*/}

                {/*moods.map((mood) => {
                const selected = isSelected(mood, "mood");
                return (
                  <Listbox.Option
                    as="li"
                    key={mood.id}
                    value={mood}
                    className={`${mood.name.replace(/[^A-Za-z0-9]/g, "")} mood`}
                  >
                    <>
                      <div
                        className={
                          !!selected ? "selected-check" : "unselected-check"
                        }
                      ></div>
                      <span>{mood.name}</span>
                    </>
                  </Listbox.Option>
                );
              })*/}
              </Listbox.Options>
            </motion.div>
          )}
        </AnimatePresence>
      </Listbox>
    </>
  );
};

export default MobileSelectMenu;
