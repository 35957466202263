import React from "react";
import passLogo from "../../../assets/images/pass/inkind-pass.png";
import closeIcon from "../../../assets/images/map_v2/close-ic-black.svg";
import expand from "../../../assets/images/map_v2/expand_more.svg";
import { motion } from "framer-motion";

const PassPopup = ({
  infoOpenMobile,
  curLoc,
  curBrand,
  popUpOpen,
  setPopUpOpen,
  maxBonus,
  purchaseModeTiers,
  totalLocationCount,
}) => {
  return (
    <motion.div
      className={
        !infoOpenMobile && !curLoc && !curBrand
          ? "pass-popup"
          : "pass-popup hide-on-small"
      }
      id="pass-popup"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="pass-header">
        <img src={passLogo} alt="inKind Pass" className="pass-logo" />
        <img
          src={closeIcon}
          alt="close button"
          className="close-logo"
          id="popup-close"
          onClick={() => setPopUpOpen(!popUpOpen)}
        />
      </div>
      {!!purchaseModeTiers ? (
        <div className="pass-container">
          <div className="pass-headline">Get a bonus at every location!</div>
          <div>
            Purchase one account, redeemable at&nbsp;
            <span className="text-bold">hundreds</span> of inKind partner
            venues.
          </div>
          <a href="https://pass.inkind.com" target="blank">
            <div className="pass-button">
              Get up to ${maxBonus} on us!
              <img src={expand} alt="inKind Pass" className="arrow-logo" />
            </div>
          </a>
        </div>
      ) : (
        <div className="pass-container">
          <div className="pass-headline">Unlock 20% back on dining</div>
          <div>
            Earn 20% back when you dine at any of inKind's {totalLocationCount}+
            restaurants and bars.
          </div>
          <a href="https://pass.inkind.com" target="blank">
            <div className="pass-button">
              Try it free for 30 days
              <img src={expand} alt="inKind Pass" className="arrow-logo" />
            </div>
          </a>
        </div>
      )}
    </motion.div>
  );
};

export default PassPopup;
