import React from "react";
import { InputLayoutProps } from "../../../helpers/types";

const InputLayout = ({
  children,
  label,
  error = null,
  showLabel = true,
  overrideClass = null,
  alignmentOverride = false,
}: InputLayoutProps) => {
  const styleOverride = alignmentOverride ? { paddingTop: "5px" } : {};

  return (
    <div className={(overrideClass && !error) || "input-error-col"}>
      <div
        className={
          overrideClass ||
          `field floating-label-wrapper outline ${
            error && showLabel ? "error-outline" : ""
          }`
        }
        style={styleOverride}
      >
        {children}
        {showLabel && (
          <label
            className={`floating-label ${
              error && showLabel ? "error-label" : ""
            }`}
          >
            {label}
          </label>
        )}
      </div>
      {error && showLabel && <span className="error-msg">{error}</span>}
    </div>
  );
};

export default InputLayout;
