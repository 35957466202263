/** @jsx jsx */
import { css, jsx } from "@emotion/react";

export default function Tooltip({ text }) {
  return (
    <div
      css={css`
        position: absolute;
        top: -40px;
        left: -15px;
        background: #fff;
        color: #000;
        padding: 7px 9px;
        z-index: 1000;
        border-radius: 5px;
        font-size: 10px;
        pointer-events: none;
      `}
      className={`toolti-data-content`}
    >
      {text}
    </div>
  );
}
