import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserByToken, getStripeSources } from "../../services/auth";
import { getCookie, deleteCookie } from "../../../../lib/helpers";

export const fetchUserByToken = createAsyncThunk(
  "users/fetchByIdStatus",

  async (thunkAPI, { rejectWithValue }) => {
    let userToken = getCookie("userToken") || null;
    if (userToken) {
      try {
        const response = await getUserByToken(userToken);
        return JSON.stringify(response.data.user);
      } catch (e) {
        if (!e.repsonse) {
          throw e;
        }
        rejectWithValue(e.response.data);
      }
    } else {
      throw error;
    }
  },
);

export const fetchSavedCards = createAsyncThunk(
  "users/fetchCardsbyToken",
  async (thunkAPI, { rejectWithValue }) => {
    let userToken = getCookie("userToken") || null;
    if (userToken) {
      try {
        const response = await getStripeSources(userToken);
        if (response.data.credit_cards.length) {
          return response.data.credit_cards;
        } else {
          throw error;
        }
      } catch (e) {
        if (!e.repsonse) {
          throw e;
        }
        rejectWithValue(e.response.data);
      }
    } else {
      throw error;
    }
  },
);

//Initial state

const initialState = {
  user: null,
  newUser: true,
  massGiftNewUser: "true",
  newFirstName: "",
  newLastName: "",
  newEmail: "",
  newPhone: "",
  loadingUser: "idle",
  newCard: true,
  loadingCards: "idle",
  selectedCard: null,
  savedCards: null,
  subscription: 0,
  subscription_status: null,
  is_freemium_subscriber: false,
  triedCards: false,
  emailAgreed: false,
  smsAgreed: false,
  agreeAll: false,
  is_app_installed: false,
};

// Create reducers/actions

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload;
      state.subscription_status = JSON.parse(
        action.payload,
      ).subscription_status;
      state.is_freemium_subscriber = JSON.parse(
        action.payload,
      ).is_freemium_subscriber;
      state.newUser = false;
    },
    logOutUser: (state) => {
      state.user = null;
      state.subscription = 0;
      state.subscription_status = null;
      state.is_freemium_subscriber = false;
      deleteCookie();
      state.savedCards = null;
      state.selectedCard = null;
      state.newCard = true;
      state.triedCards = false;
    },
    setNewFirstName: (state, action) => {
      state.newFirstName = action.payload;
    },
    setNewLastName: (state, action) => {
      state.newLastName = action.payload;
    },
    setNewEmail: (state, action) => {
      state.newEmail = action.payload;
    },
    setPhone: (state, action) => {
      state.newPhone = action.payload;
    },
    setEmailAgreed: (state, action) => {
      state.emailAgreed = action.payload;
    },
    setSmsAgreed: (state, action) => {
      state.smsAgreed = action.payload;
    },
    setAgreeAll: (state, action) => {
      state.agreeAll = action.payload;
    },
    setReturnUser: (state) => {
      state.newUser = false;
    },
    setNewUser: (state) => {
      state.newUser = true;
    },
    setNewCard: (state, action) => {
      state.newCard = action.payload;
    },
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setSubscriptionStatus: (state, action) => {
      state.subscription_status = action.payload;
    },
    setIsFreemiumSubscriber: (state, action) => {
      state.is_freemium_subscriber = action.payload;
    },
    setmassGiftNewUser: (state, action) => {
      state.massGiftNewUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchUserByToken.fulfilled, (state, action) => {
        // Add user to the state array
        if (action.payload != null || undefined) {
          state.user = action.payload;
          state.subscription = JSON.parse(action.payload).subscription_level_id;

          state.subscription_status = JSON.parse(
            action.payload,
          ).subscription_status;
          state.is_freemium_subscriber = JSON.parse(
            action.payload,
          ).is_freemium_subscriber;
          state.newUser = false;
          state.is_app_installed = JSON.parse(action.payload).is_app_installed;
        }
        state.loadingUser = "idle";
      })
      .addCase(fetchUserByToken.pending, (state, action) => {
        if (state.loadingUser === "idle") {
          state.loadingUser = "pending";
        }
      })
      .addCase(fetchUserByToken.rejected, (state, action) => {
        if (state.loadingUser === "pending") {
          state.loadingUser = "idle";
          state.newUser = true;
        }
      })
      .addCase(fetchSavedCards.fulfilled, (state, action) => {
        const sortedCards = action.payload;
        sortedCards.forEach(function (item, i) {
          if (item.is_autopay === true) {
            sortedCards.splice(i, 1);
            sortedCards.unshift(item);
          }
        });
        state.savedCards = sortedCards;
        state.selectedCard = action.payload[0];
        state.newCard = false;
        state.loadingCards = "idle";
      })
      .addCase(fetchSavedCards.pending, (state, action) => {
        state.loadingCards = "pending";
      })
      .addCase(fetchSavedCards.rejected, (state, action) => {
        state.loadingCards = "idle";
        state.triedCards = true;
      });
  },
});

// Export actions

export const {
  loginUser,
  logOutUser,
  setNewFirstName,
  setNewLastName,
  setNewEmail,
  setPhone,
  setEmailAgreed,
  setSmsAgreed,
  setAgreeAll,
  setReturnUser,
  setNewUser,
  loadingUser,
  setNewCard,
  setSelectedCard,
  setSubscription,
  setSubscriptionStatus,
  setIsFreemiumSubscriber,
  setmassGiftNewUser,
} = userSlice.actions;

export default userSlice.reducer;
