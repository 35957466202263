import React from "react";

const PassTier = ({ payAmount, getAmount, bonusAmount, icoId }) => {
  return (
    <a
      href={`${origin.replace("app", "pass")}/checkout?ico_id=${icoId}`}
      target="blank"
    >
      <div className="map-tier">
        <span>
          <span className="text-bold">Get ${getAmount}</span>, For ${payAmount}
        </span>
        <span className="map-tier-button text-bold">${bonusAmount} BONUS</span>
      </div>
    </a>
  );
};

export default PassTier;
