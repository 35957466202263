import React from "react";

const ZoomBox = ({ map, setCenter, viewCenter }) => {
  function handleZoom(which) {
    map.setZoom(map.getZoom() + which);
  }

  return (
    <div className="zoom-box">
      <div
        className="zoom-in"
        onClick={() => {
          handleZoom(1);
          !!viewCenter
            ? setCenter(viewCenter, map)
            : setCenter(viewCenter, map, "negative");
        }}
      >
        +
      </div>
      <div
        className="zoom-out"
        onClick={() => {
          handleZoom(-1);
          !!viewCenter
            ? setCenter(viewCenter, map)
            : setCenter(viewCenter, map, "offset");
        }}
      >
        -
      </div>
    </div>
  );
};

export default ZoomBox;
