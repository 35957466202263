import React from "react";
import { Listbox } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";

const SelectMenu = ({
  selectedItems,
  colorMode,
  widget,
  setSelectedItems,
  setOther,
  selectCategories,
  name,
  isOpen,
  setIsOpen,
}) => {
  const isSelected = (val) => {
    return selectedItems?.name === val?.name ? true : false;
  };

  const handleSelectedItems = (val) => {
    let selectedItemsUpdated;
    if (!isSelected(val)) {
      selectedItemsUpdated = val;
    } else {
      selectedItemsUpdated = null;
    }
    setOther(null);
    setSelectedItems(selectedItemsUpdated);
    setIsOpen(false);
  };

  return (
    <Listbox
      as="div"
      value={selectedItems}
      onChange={(val) => handleSelectedItems(val)}
      open={isOpen}
    >
      <a onClick={() => setIsOpen(!isOpen)} className="open-menu-button">
        <Listbox.Button
          as="button"
          className={`menu-button ${
            widget === true && colorMode === "light"
              ? "light-menu-button"
              : colorMode === "black"
                ? "black-menu-button"
                : ""
          }`}
        >
          {!selectedItems ? (
            <span>{name}</span>
          ) : (
            <span className="selected-items">{selectedItems.name}</span>
          )}
        </Listbox.Button>
      </a>
      <AnimatePresence>
        {!!isOpen && (
          <motion.div
            key="selectMenu"
            className="menu-items-container"
            id="list-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Listbox.Options
              as="ul"
              className={`menu-items ${
                widget === true && colorMode === "light"
                  ? "light-menu-items"
                  : colorMode === "black"
                    ? "black-menu-items"
                    : ""
              }`}
              static
            >
              <div className="menu-header" onClick={() => setIsOpen(false)}>
                <div className="menu-title">{name}</div>
                <a className="clear" onClick={() => setSelectedItems(null)}>
                  Clear
                </a>
              </div>

              {selectCategories.map((item) => {
                const selected = isSelected(item);
                return (
                  <Listbox.Option as="li" key={item.id} value={item}>
                    <>
                      <div
                        className={
                          !!selected ? "selected-check" : "unselected-check"
                        }
                      ></div>
                      <span>{item.name}</span>
                    </>
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </motion.div>
        )}
      </AnimatePresence>
    </Listbox>
  );
};

export default SelectMenu;
