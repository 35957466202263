import React from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
const HouseAccountButton = ({
  pay,
  give,
  classNames,
  strike,
  pay_verb,
  get_verb,
  ribbon_color,
  id,
  most_popular,
  button_color,
  standard,
  ribbon_font,
  banner_label,
  strike_through_mode,
  tiers_banner_mode,
  utm_values,
  inkind_cash,
  shareUrl,
}) => {
  let percent_free = Math.round(((give - pay) / pay) * 100);
  let urlUse = !!shareUrl ? shareUrl + "checkout?" : "/checkout?";
  let bonus = give - pay;
  let useBonus =
    String(bonus).slice(-1) === "6" || String(bonus).slice(-1) === "1"
      ? bonus - 1
      : bonus;
  return (
    <a
      href={`${urlUse}${inkind_cash ? "ico_id" : "gco_id"}=${id}${
        utm_values ? `&${utm_values.substring(1)}` : ""
      }`}
      className="tier-link"
    >
      <div className="account-choice-wrapper">
        {(tiers_banner_mode == "bonus" ||
          tiers_banner_mode === "percent_free" ||
          tiers_banner_mode === "percent_bonus") &&
        !standard ? (
          <>
            <svg
              width="200px"
              className="ribbon"
              height="24px"
              viewBox="0 0 166 24"
              version="1.1"
            >
              <g
                id="New-Purchase-Pages"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="Path-4"
                  fill={ribbon_color}
                  points="0.651956098 0.18907563 12.4214941 12 0.651956098 23.5120401 165.501393 23.5120401 155.089341 12 165.501393 0.18907563"
                ></polygon>
              </g>
            </svg>
            <div
              style={{ color: ribbon_font }}
              className={classNames ? classNames : "ribbon-text font-bold"}
            >
              {banner_label
                ? banner_label
                : tiers_banner_mode === "bonus"
                  ? `$${useBonus} BONUS`
                  : `${percent_free}% ${
                      tiers_banner_mode === "percent_free" ? "FREE" : "BONUS"
                    }`}
            </div>
          </>
        ) : (
          tiers_banner_mode === "most_popular" &&
          most_popular && (
            <>
              <svg
                width="200px"
                className="ribbon"
                height="24px"
                viewBox="0 0 166 24"
                version="1.1"
              >
                <g
                  id="New-Purchase-Pages"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <polygon
                    id="Path-4"
                    fill={ribbon_color}
                    points="0.651956098 0.18907563 12.4214941 12 0.651956098 23.5120401 165.501393 23.5120401 155.089341 12 165.501393 0.18907563"
                  ></polygon>
                </g>
              </svg>
              <div
                style={{ color: ribbon_font }}
                className="ribbon-text font-bold"
              >
                MOST POPULAR
              </div>
            </>
          )
        )}
        <button
          className="hero-button2"
          style={{ backgroundColor: button_color }}
        >
          {(!strike || Number(pay) < 10000) && (
            <div className="button-chevron">
              <ChevronRightIcon className="chevron" />
            </div>
          )}
          <div className="w-100">
            {!strike ? (
              standard ? (
                <>
                  <span className="font-bold">
                    ${Number(give).toLocaleString()} E-Gift Card
                  </span>
                </>
              ) : (
                <>
                  {pay_verb} ${Number(pay).toLocaleString()}, {get_verb}
                  <span>&nbsp;</span>
                  <span className="font-bold">
                    $
                    {tiers_banner_mode === "percent_free" ||
                    tiers_banner_mode === "percent_bonus"
                      ? Number(give - pay).toLocaleString()
                      : Number(give).toLocaleString()}
                  </span>
                </>
              )
            ) : (
              <>
                {" "}
                <span>
                  {pay_verb} ${Number(pay).toLocaleString()},<span>&nbsp;</span>
                </span>
                <span className="font-bold">
                  {get_verb}
                  <span>&nbsp;</span>
                  <span
                    className={
                      strike_through_mode === "animated"
                        ? "purchase-page__gco-strike-through-amount"
                        : "unanimated-amount"
                    }
                  >
                    <span>
                      {String(strike).length <= 3 && <>&nbsp;</>}$
                      {tiers_banner_mode === "percent_free" ||
                      tiers_banner_mode === "percent_bonus"
                        ? Number(strike - pay).toLocaleString()
                        : Number(strike).toLocaleString()}
                      &nbsp;
                    </span>
                  </span>
                  <span
                    className={
                      strike_through_mode === "animated"
                        ? "purchase-page__gco-strike-through-new-get-amount"
                        : "unanimated-get"
                    }
                  >
                    <span>&nbsp;</span>$
                    {tiers_banner_mode === "percent_free" ||
                    tiers_banner_mode === "percent_bonus"
                      ? Number(give - pay).toLocaleString()
                      : Number(give).toLocaleString()}
                  </span>
                </span>{" "}
              </>
            )}
          </div>
        </button>
      </div>
    </a>
  );
};

export default HouseAccountButton;
