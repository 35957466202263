export const InKindNetworkError = (
  err,
  defaultErrorMessage = "Unknown Error Occurred",
) => {
  // If an array of errors was passed, then reassign variable to first variable in list
  if (Array.isArray(err)) {
    err = err[0];
  }

  // If an axios request but no response, assume no internet connection
  if (err?.request && err?.response == undefined) {
    return Error(
      "We could not connect to inKind servers, please check your internet connection.",
    );
  }

  // If there is an array of errors
  if (err?.response?.data.errors?.[0] != null) {
    const errorsList = err?.response?.data.errors;
    const parsedError = parseErrorData(errorsList);
    if (parsedError != null) return parsedError;
  }

  // If there is a nested JSON object response
  if (err?.response?.data?.error != undefined) {
    const parsedError = parseErrorData(err?.response?.data?.error);
    if (parsedError != null) return parsedError;
  }

  // If a simple string
  if (typeof err == "string") {
    return Error(err);
  }

  // If we have captured a normal exception
  if (err instanceof Error) {
    return err;
  }

  // Have we captured an error-like object
  if ("message" in err) {
    return Error(err?.message);
  }

  // Welp... Let's just show the backup value
  return Error(defaultErrorMessage);
};

const parseErrorData = (errorIn) => {
  try {
    let error = errorIn;

    // If a simple string
    if (typeof error == "string") {
      return Error(error);
    }

    // If a JSON object or Array
    if (typeof error == "object") {
      error =
        Array.isArray(error) == false
          ? // Get the first error in nested error object errorsList.credit_card_charge = string | [string]
            error[Object.keys(error)[0]]
          : // Get the first error in error array
            error[0];
    }

    // A nested array?! Use the first value
    if (Array.isArray(error)) {
      error = error[0];
    }

    // Yay... a string
    if (typeof error == "string") {
      return Error(error);
    }

    if (error?.message) {
      return Error(error.message);
    }
  } catch {}
};
