import React from "react";
import InputLayout from "../../ui/molecules/InputLayout.tsx";
import { useFormikContext } from "formik";
import ForgotPassword from "./ForgotPassword";
import PartnerPortalInputWrapper from "../partner-portal/molecules/PartnerPortalInputWrapper.tsx";
import ConditionalWrapper from "../../ui/atoms/ConditionalWrapper/ConditionalWrapper.jsx";
import "detect-autofill";

const SignIn = ({ isPartnerPortal }) => {
  const { values, handleBlur, handleChange, errors, submitForm } =
    useFormikContext();
  const [didAutoComplete, setDidAutoComplete] = React.useState(false);

  React.useEffect(() => {
    if (didAutoComplete && values.email) {
      setDidAutoComplete(false);
      submitForm();
    }
  }, [didAutoComplete, values.password]);

  React.useEffect(() => {
    document.addEventListener("onautocomplete", function (e) {
      setDidAutoComplete(e.target.hasAttribute("autocompleted"));
    });
  }, []);

  return (
    <>
      <ConditionalWrapper
        condition={isPartnerPortal}
        wrapper1={(children) => (
          <InputLayout label="Email" error={errors.email}>
            {children}
          </InputLayout>
        )}
        wrapper2={(children) => (
          <PartnerPortalInputWrapper label="Email" name={"email"}>
            {children}
          </PartnerPortalInputWrapper>
        )}
      >
        <div
          type="email"
          name="email"
          aria-label="email"
          className="floating-label-input login-ca__disabled-field"
          autoComplete="email"
        >
          {values.email}
        </div>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={isPartnerPortal}
        wrapper1={(children) => (
          <InputLayout label="Password" error={errors.password}>
            {children}
          </InputLayout>
        )}
        wrapper2={(children) => (
          <PartnerPortalInputWrapper label="Password" name={"password"}>
            {children}
          </PartnerPortalInputWrapper>
        )}
      >
        <input
          type="password"
          name="password"
          aria-label="password"
          placeholder=" "
          className="floating-label-input"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="current-password"
        />
      </ConditionalWrapper>
      <div className="login-ca__options-container">
        <ForgotPassword
          email={values.email}
          isPartnerPortal={isPartnerPortal}
        />
      </div>
    </>
  );
};

export default SignIn;
