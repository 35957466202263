/** @jsx jsx */
import { css, jsx } from "@emotion/react";

export function BelowInputContentAddPhoneNumber() {
  const handlePrivacyPolicy = () => {
    window.open("https://inkind.com/privacy", "_blank");
  };
  const handleTermsOfUse = () => {
    window.open("https://inkind.com/terms", "_blank");
  };
  return (
    <div className="below-input-content">
      <p>By continuing, I agree to inKind’s</p>
      <p>
        <button
          type="button"
          onClick={handlePrivacyPolicy}
          className="privacy-policy"
        >
          Privacy Policy
        </button>
        &nbsp;and&nbsp;
        <button
          type="button"
          onClick={handleTermsOfUse}
          className="terms-of-use"
        >
          Terms of Use
        </button>
      </p>
    </div>
  );
}

export function BelowInputContentOtpScreen() {
  const handlePrivacyPolicy = () => {
    window.open("https://inkind.com/privacy", "_blank");
  };
  const handleTermsOfUse = () => {
    window.open("https://inkind.com/terms", "_blank");
  };
  return (
    <div className="below-input-content">
      <div
        className="checkbox-and-text"
        css={css`
          display: flex;
          align-items: flex-start;
        `}
      >
        <input
          type="checkbox"
          css={css`
            margin-right: 10px;
          `}
        />
        <p>
          Sign up for emails to get updates from inKind on products, offers and
          your Membership benefits
        </p>
      </div>
      <div
        className="checkbox-and-text"
        css={css`
          display: flex;
          align-items: flex-start;
        `}
      >
        <input
          type="checkbox"
          css={css`
            margin-right: 10px;
          `}
        />
        <p>
          I agree to inKind’s &nbsp;
          <button
            type="button"
            onClick={handlePrivacyPolicy}
            className="privacy-policy"
          >
            Privacy Policy
          </button>
          &nbsp;and &nbsp;
          <button
            type="button"
            onClick={handleTermsOfUse}
            className="terms-of-use"
          >
            Terms of Use
          </button>
        </p>
      </div>
    </div>
  );
}
