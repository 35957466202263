import React from "react";
import phoneHandIcon from "../../../assets/images/icons/phone-hand.svg";
import { handlePost } from "./utilities";
import useDeviceId from "@/events/useDeviceId";
import useSessionId from "@/events/useSessionId";

const VerifyYourEmail = ({
  handleResult,
  emailToken,
  isPartnerPortal,
  inviteToken,
}) => {
  const deviceId = useDeviceId();
  const sessionId = useSessionId();
  const checkVerified = async () => {
    const header = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    const path = "/api/v5/verifications/email/verify";
    const bodyJson = JSON.stringify({
      email_token: emailToken,
      invite_token: isPartnerPortal ? inviteToken : "",
    });
    const result = await handlePost(path, bodyJson, undefined, header);
    if (result) {
      handleResult(result);
    }
  };

  React.useEffect(() => {
    const checkInterval = setInterval(() => {
      checkVerified();
    }, 15000);

    //clear interval on unmount
    return () => {
      clearInterval(checkInterval);
    };
  }, [sessionId]);

  return (
    <img src={phoneHandIcon} alt="Verify phone" className="login-ca__image" />
  );
};

export default VerifyYourEmail;
