/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useState, useEffect } from "react";
import CopyIcon from "../../../../assets/images/ambassador/CopyIcon.svg";
import ShareIcon from "../../../../assets/images/ambassador/share-icon.svg";
import Tooltip from "../2fa-user-flow/Tooltip";
import PropTypes from "prop-types";
import { share, copyLink, isMobileDevice } from "./helpers";

export default function LinkGenerated({
  backgroundColor,
  fontFamily,
  offerData,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [shareApiAvailable, setShareApiAvailable] = useState(null);
  useEffect(() => {
    if (navigator.share) {
      setShareApiAvailable(true);
    } else {
      setShareApiAvailable(false);
    }
  }, []);
  return (
    <>
      <div className="link-generated-section">
        <h3
          css={css`
            background: ${backgroundColor};
            font-family: ${fontFamily}, sans-serif;
          `}
          className={"share-link-content"}
        >
          <span className="link-text">{offerData?.referral_link || ""}</span>
          <div className="copy-and-share-icons">
            {offerData?.referral_link && (
              <button
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default for spacebar
                    copyLink(); // Trigger click event on Enter or Space
                  }
                }}
                onClick={() =>
                  copyLink(offerData?.referral_link, setShowTooltip)
                }
                className="copy-icon"
              >
                <img src={CopyIcon} alt={`${CopyIcon}-icon`} />
                {showTooltip && <Tooltip text={"Copied"} />}
              </button>
            )}
            {shareApiAvailable && isMobileDevice() && (
              <button
                onClick={() => share(offerData)}
                className="share-icon"
                css={css`
                  width: 18px;
                  cursor: pointer;
                `}
              >
                <img src={ShareIcon} alt={`${ShareIcon}-icon`} />
              </button>
            )}
          </div>
        </h3>
      </div>
    </>
  );
}

LinkGenerated.propTypes = {
  backgroundColor: PropTypes.string,
  offerData: PropTypes.object,
  fontFamily: PropTypes.string,
};
