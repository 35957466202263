import React from "react";

const AdminModal = ({ children }) => {
  return (
    <div className="admin-modal__outer">
      <div className="admin-modal__modal">{children}</div>
    </div>
  );
};

export default AdminModal;
