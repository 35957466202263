import React from "react";
import EmptyImageIcon from "../../../assets/images/icons/empty-image-light.svg";

const EmptyImage = () => {
  return (
    <figure className="empty-image-container">
      <div className="empty-image-mass-gifting">
        <img
          src={EmptyImageIcon}
          alt="empty"
          style={{ height: "32px", width: "32px" }}
        />
      </div>
    </figure>
  );
};

export default EmptyImage;
