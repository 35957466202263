import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isFutureDate } from "@lib/helpers";
import numeral from "numeral";

function today() {
  let date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  let datestr = date.toISOString().substring(0, 10);
  return datestr;
}

function getPayPercent(initNum, initPercent) {
  //From a discount percent (eg, 20%) and an initial price (eg, $2000), get the final payment value (eg, $1600)
  //by multiplying by a precentage of 1 calculated from the discount (eg, .8 for 20%)
  return Number(initNum) * (1 - Number(initPercent) / 100);
}

const initialState = {
  numsToUse: [
    { percent: 10, min: null, max: 5000 },
    { percent: 15, min: 5000, max: 10000 },
    { percent: 20, min: 10000, max: 25000 },
    { percent: 25, min: 25000, max: null },
  ],
  recipUpdating: false,
  giftAmount: 0,
  pageStatus: "createGift",
  sendersName: "",
  emailTitle: "Enjoy something delicious",
  giftMessage: "",
  companyLogo: null,
  deliverToMe: false,
  calcBeforeSavings: 0,
  calcAfterSavings: 0,
  calcBonus: 0,
  submitted: false,
  recurring: false,
  recurringFrequency: null,
  deliveryDate: today(),
  customGiftRecips: { recipients: [], customSpend: 0 },
  standardRecips: [],
  selectedList: null,
  recipCount: 0,
  showToast: false,
  fullList: [],
  loadingLists: "idle",
  discountIndex: 0,
  discountArr: ["10%", "15%", "20%", "25%"],
  bonus: 0,
  untilNextTier: 5000,
  tier: 1,
  targetedSavings: 15,
  targetedRecipients: 0,
  totalSavings: 0,
  errors: {
    giftAmount: null,
    giftMessage: null,
    recurring: null,
    date: null,
    list: null,
  },
};

export const massGiftSlice = createSlice({
  name: "massGift",
  initialState,
  reducers: {
    setLoggedOut: (state, action) => {
      (state.numsToUse = initialState.numsToUse),
        (state.recipUpdating = initialState.recipUpdating),
        (state.giftAmount = initialState.giftAmount),
        (state.pageStatus = initialState.pageStatus),
        (state.sendersName = initialState.sendersName),
        (state.emailTitle = initialState.emailTitle),
        (state.giftMessage = initialState.giftMessage),
        (state.companyLogo = initialState.companyLogo),
        (state.deliverToMe = initialState.deliverToMe),
        (state.calcBeforeSavings = initialState.calcBeforeSavings),
        (state.calcAfterSavings = initialState.calcAfterSavings),
        (state.calcBonus = initialState.calcBonus),
        (state.submitted = initialState.submitted),
        (state.recurring = initialState.recurring),
        (state.recurringFrequency = initialState.recurringFrequency),
        (state.deliveryDate = initialState.deliveryDate),
        (state.customGiftRecips = initialState.customGiftRecips),
        (state.standardRecips = initialState.standardRecips),
        (state.selectedList = initialState.selectedList),
        (state.recipCount = initialState.recipCount),
        (state.fullList = initialState.fullList),
        (state.loadingLists = initialState.loadingLists),
        (state.discountIndex = initialState.discountIndex),
        (state.discountArr = initialState.discountArr),
        (state.bonus = initialState.bonus),
        (state.untilNextTier = initialState.untilNextTier),
        (state.tier = initialState.tier),
        (state.targetedSavings = initialState.targetedSavings),
        (state.targetedRecipients = initialState.targetedRecipients),
        (state.totalSavings = initialState.totalSavings),
        (state.errors = initialState.errors);
    },
    setRecipUpdating: (state, action) => {
      state.recipUpdating = action.payload;
    },
    setTiers: (state, action) => {
      state.numsToUse = action.payload;
    },
    setGiftAmount: (state, action) => {
      state.giftAmount = action.payload;
      state.beforeSavings =
        state.standardGifts * Number(state.giftAmount) +
        state.customGiftRecips.customSpend;
      state.errors.giftAmount = null;

      if (state.beforeSavings >= state.numsToUse[3].min) {
        state.discount = state.numsToUse[3].percent + "%";
        state.untilNextTier = "highest";
        state.tier = 4;
        state.afterSavings =
          state.beforeSavings * (1 - Number(state.numsToUse[3].percent) / 100);
      } else if (state.beforeSavings >= state.numsToUse[2].min) {
        state.discount = state.numsToUse[2].percent + "%";
        state.tier = 3;
        state.afterSavings =
          state.beforeSavings * (1 - Number(state.numsToUse[2].percent) / 100);
        state.untilNextTier = state.numsToUse[3].min - state.beforeSavings;
      } else if (state.beforeSavings >= state.numsToUse[1].min) {
        state.discount = state.numsToUse[1].percent + "%";
        state.tier = 2;
        state.afterSavings =
          state.beforeSavings * (1 - Number(state.numsToUse[1].percent) / 100);
        state.untilNextTier = state.numsToUse[2].min - state.beforeSavings;
      } else {
        state.tier = 1;
        state.discount = state.numsToUse[0].percent + "%";
        state.afterSavings =
          state.beforeSavings * (1 - Number(state.numsToUse[0].percent) / 100);
        state.untilNextTier = state.numsToUse[1].min - state.beforeSavings;
      }
    },
    setSelectedList: (state, action) => {
      state.selectedList = action.payload;
      state.errors.list = null;
    },
    setRecurring: (state, action) => {
      state.recurring = action.payload;
      state.errors.recurring = null;
    },
    setRecurringFrequency: (state, action) => {
      state.recurringFrequency = action.payload;
      state.errors.recurring = null;
    },
    setSendersName: (state, action) => {
      state.sendersName = action.payload;
      state.errors.giftMessage = null;
    },
    setShowToast: (state, action) => {
      state.showToast = action.payload;
    },
    setGiftMessage: (state, action) => {
      state.giftMessage = action.payload;
    },
    setEmailTitle: (state, action) => {
      state.emailTitle = action.payload;
    },
    setCompanyLogo: (state, action) => {
      state.companyLogo = action.payload;
    },
    setDeliveryDate: (state, action) => {
      state.deliveryDate = action.payload;
      state.errors.date = null;
    },
    setDeliverToMe: (state, action) => {
      state.deliverToMe = action.payload;
      state.errors.date = null;
    },
    setPageStatus: (state, action) => {
      state.pageStatus = action.payload;
    },
    setFullList: (state, action) => {
      state.fullList = action.payload;
      state.recipCount = action.payload.length;
    },
    setRecipCount: (state, action) => {
      state.recipCount = action.payload;
      // total spend amount is number of standard gits times (recipient count - length of custom gifts array) + total amount of custom gifts MINUS percentage
    },
    setTargetedRecipients: (state, action) => {
      state.targetedRecipients = action.payload;
    },
    setTargetedSavings: (state, action) => {
      state.targetedSavings = action.payload;
    },
    setTotalSavings: (state, action) => {
      state.totalSavings = action.payload;
    },
    setCustomGiftRecips: (state, action) => {
      state.customGiftRecips.recipients = action.payload;
      let customSpend = 0;
      state.customGiftRecips.recipients.map((recip) => {
        let recipAmount = numeral(recip.amount);
        customSpend = customSpend += recipAmount.value();
      });
      state.customGiftRecips.customSpend = customSpend;
      let standardGifts = state.recipCount
        ? state.recipCount - state.customGiftRecips.recipients.length
        : 0;
      state.standardGifts = standardGifts;
      let beforeSavings =
        standardGifts * Number(state.giftAmount) + customSpend;
      state.beforeSavings = beforeSavings;

      if (beforeSavings >= state.numsToUse[3].min) {
        state.discount = state.numsToUse[3].percent + "%";
        state.untilNextTier = "highest";
        state.tier = 4;
        state.afterSavings = getPayPercent(
          beforeSavings,
          state.numsToUse[3].percent,
        );
      } else if (beforeSavings >= state.numsToUse[2].min) {
        state.discount = state.numsToUse[2].percent + "%";
        state.tier = 3;
        state.afterSavings = getPayPercent(
          beforeSavings,
          state.numsToUse[2].percent,
        );
        state.untilNextTier = state.numsToUse[3].min - state.beforeSavings;
      } else if (beforeSavings >= state.numsToUse[1].min) {
        state.discount = state.numsToUse[1].percent + "%";
        state.tier = 2;
        state.afterSavings = getPayPercent(
          beforeSavings,
          state.numsToUse[1].percent,
        );
        state.untilNextTier = state.numsToUse[2].min - state.beforeSavings;
      } else {
        state.tier = 1;
        state.discount = state.numsToUse[0].percent + "%";
        state.afterSavings = getPayPercent(
          beforeSavings,
          state.numsToUse[0].percent,
        );
        state.untilNextTier = state.numsToUse[1].min - state.beforeSavings;
      }
    },
    submitGift: (state) => {
      if (state.afterSavings < 100) {
        state.errors.giftAmount = "Minimum payment amount is $100";
      }
      if (state.recurring && !state.recurringFrequency) {
        state.errors.recurring = "Please set frequency";
      }
      if (!state.sendersName) {
        state.errors.giftMessage = "Sender's name is required";
      }
      if (!isFutureDate(state.deliveryDate, state.deliverToMe)) {
        state.errors.date = "Please select a date in the future";
      }
      if (!state.selectedList) {
        state.errors.list = "Select or create a valid recipient list";
      }
      if (Object.values(state.errors).every((element) => element === null)) {
        state.pageStatus = "payment";
      }
    },
  },
});

// Export actions

export const {
  setLoggedOut,
  setRecipUpdating,
  setEmailTitle,
  setGiftAmount,
  setSendersName,
  setGiftMessage,
  setCompanyLogo,
  setDeliverToMe,
  submitGift,
  setRecurring,
  setRecurringFrequency,
  setDeliveryDate,
  setSelectedList,
  setPageStatus,
  setRecipCount,
  setFullList,
  setCustomGiftRecips,
  setShowToast,
  setTargetedRecipients,
  setTargetedSavings,
  setTotalSavings,
  setTiers,
} = massGiftSlice.actions;

export default massGiftSlice.reducer;
