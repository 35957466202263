/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useContext, useState, useEffect } from "react";
import Background2fa from "../../../../assets/images/ambassador/background-2fa-desktop.svg";
import { calculateFontColorFor } from "../2fa-user-flow/index";
import { ListContext } from "./";
import CopyIcon from "../../../../assets/images/ambassador/CopyIcon.svg";
import Tooltip from "../2fa-user-flow/Tooltip";
import PropTypes from "prop-types";
import { getCookie } from "../../../lib/helpers";

export default function ListItem({ item }) {
  const {
    isLoggedIn,
    user,
    setStep,
    setAllOffersData,
    allOffersData,
    setShowAmbassadorModal,
    setModalData,
  } = useContext(ListContext);
  const [offerData, setOfferData] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [setError] = useState(null);
  const brandLogo = item?.brand_dark_logo;

  useEffect(() => {
    if (isLoggedIn && item?.referral_link) {
      const data = {
        offer: item?.offer,
        referral_link: item?.referral_link,
        completed_referrals: item?.completed_referrals,
        earned: item?.earned,
      };
      setOfferData(data);
      setAllOffersData((prevData) => [...prevData, data]);
    }
  }, [isLoggedIn, item]);
  const generateLink = (e) => {
    e.stopPropagation();
    if (isLoggedIn) {
      const currentBrandSlug = item?.brand_slug;
      async function fetchOfferData() {
        const headers = {
          Authorization: "Bearer ".concat(user.user.token),
          "content-type": "application/json",
        };
        const arr = item?.ambassador_program_link.split("/");
        await fetch("/api/v5/ambassadors", {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            brand_slug: arr[arr.length - 1],
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            setOfferData(response);
            setAllOffersData([...allOffersData, response]);
            const isModalCookieSet =
              getCookie(
                `ambassadorModalClaim${response?.welcome_offer?.claim_details?.offer_claim_id}`,
              ) === "true";
            setShowAmbassadorModal(
              !response.welcome_offer_redeemed && !isModalCookieSet,
            );
            setModalData({ item, offerData: response });
          })
          .catch((err) => {
            console.log("err", err);
            setError(err);
          });
      }
      if (allOffersData?.length > 0) {
        const offerPresent = allOffersData.find(
          (offer) => offer.offer.brand.brand_slug === currentBrandSlug,
        );
        if (offerPresent) {
          setOfferData(offerPresent);
        } else {
          fetchOfferData();
        }
        return;
      }
      fetchOfferData();
    } else {
      setStep(2);
    }
  };
  const copyLink = async () => {
    try {
      if (offerData?.referral_link) {
        // Copy from textRef if provided
        await navigator.clipboard.writeText(offerData?.referral_link);
      } else {
        // Otherwise, copy the entire document body
        await navigator.clipboard.writeText(document.body.textContent);
      } // Optional feedback to the user
      await setShowTooltip(true);
      await setTimeout(() => {
        setShowTooltip(false);
      }, 2500);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <>
      <div
        className={`top-section`}
        css={css`
          position: relative;
          margin-bottom: 5px;
          height: 290px;
          min-width: 300px;
        `}
      >
        {brandLogo && (
          <img
            src={brandLogo}
            alt="Brand logo"
            css={css`
              position: absolute;
              left: 50%;
              top: ${item?.brand_tags?.length
                ? `calc(50% - 41px/2)`
                : `calc(50% - 17px/2)`};
              transform: translate(-50%, -50%);
              max-width: 50%;
            `}
          />
        )}
        <img
          src={item.brand_hero_image_url || Background2fa}
          alt={"Brand hero"}
          className="list-item-background-image"
        />
        <div
          className="bottom-content"
          css={css`
            position: absolute;
            bottom: 8px;
            left: 5px;
            color: #fff;
          `}
        >
          <h3
            css={css`
              color: #fff;
              font-weight: 700;
              font-size: 15px;
              margin-bottom: 9px;
            `}
          >
            {item.brand_name || `Name of restaurant`}
          </h3>
          <div
            className=""
            css={css`
              display: flex;
              align-items: center;
              span {
                margin-right: 5px;
                background: #232323;
                padding: 5px 15px;
                border-radius: 15px;
                font-size: 9px;
              }
            `}
          >
            {item.brand_tags.slice(0, 3).map((tag, idx) => (
              <span key={idx + 1} className="tag-in-listitem-top-content">
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <h3
          css={css`
            text-align: center;
            background: #000;
            padding: 18px 0;
            border-radius: 8px;
            color: #fff;
            font-size: 13px;
          `}
          onClick={generateLink}
          className={
            isLoggedIn && offerData?.referral_link
              ? "link-present gen-link-button"
              : "gen-link-button"
          }
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent default for spacebar
              generateLink(e); // Trigger click event on Enter or Space
            }
          }}
        >
          <span
            css={css`
              color: #ffffff;
            `}
          >
            {offerData?.referral_link ? offerData?.referral_link : "Generate"}
          </span>
          {isLoggedIn && offerData?.referral_link ? (
            <span
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default for spacebar
                  copyLink(); // Trigger click event on Enter or Space
                }
              }}
              onClick={copyLink}
              className="copy-icon"
            >
              <img src={CopyIcon} alt={`${CopyIcon}-icon`} />
              {showTooltip && <Tooltip text={"Copied"} />}
            </span>
          ) : (
            ` $${item.referral_amount || "25"} off $${item.minimum_spend || "75"} Link`
          )}
        </h3>
      </div>
    </>
  );
}

ListItem.propTypes = {
  item: PropTypes.object,
};
