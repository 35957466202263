import React from "react";
import { Button } from "../../ui/atoms/Button";

const AdminModalHeader = ({ text, closeBehavior }) => {
  return (
    <div className="generate-logos__header">
      <span>{text}</span>
      <Button buttonClasses="admin-modal__close" onClick={closeBehavior} />
    </div>
  );
};

export default AdminModalHeader;
