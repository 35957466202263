import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const CustomCurrencyInput = ({
  currency,
  setCurrency,
  handleSubmit,
  setError = null,
  error,
  setCurInput = null,
  buttonInput,
  right = false,
  placeholder,
  maxDigits,
  mgCalc,
}) => {
  const defaultMaskOptions = {
    prefix: "$",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: !!maxDigits ? maxDigits : 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask(defaultMaskOptions);

  function handleActive(e) {
    const selected = document.querySelectorAll(".inkind-tier-button.active");
    const currencyVal = Number(e.target.dataset.currency);
    setCurInput(e.target.parentElement.getElementsByTagName("input")[0]);
    for (let i = 0; i < selected.length; i++) {
      selected[i].classList.remove("active");
    }
    e.target.classList.add("active");
    setCurrency(currencyVal);
  }

  const handleFocus = (e) => {
    if (!!buttonInput) {
      handleActive(e);
      if (!mgCalc) {
        setCurrency("");
      }
    } else if (!!mgCalc) {
      e.target.select();
    }
    setCurInput(e.target);
  };

  return (
    <MaskedInput
      value={currency !== 0 && currency !== "$_" ? currency : ""}
      onChange={({ target }) => {
        if (target.value === "$_" || target.value === "$0._") {
          target.value = "";
        }
        setCurrency(target.value);
        setError(null);
      }}
      onFocus={(e) => handleFocus(e)}
      placeholder={placeholder}
      className={
        !!buttonInput
          ? "inkind-tier-button other-button button-input"
          : `currency-input inkind-tier-button ${error ? "error-shadow" : ""} ${
              right ? "right" : ""
            }`
      }
      inputMode="numeric"
      name="ff-input"
      mask={currencyMask}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSubmit();
        }
      }}
    />
  );
};

export default CustomCurrencyInput;
