import { useState } from "react";

// Define a type for the options parameter in fetchData
interface FetchOptions extends RequestInit {}

// Define the return type for the useFetchApi hook
interface FetchApiReturnType<T> {
  data: T | null;
  error: string | null;
  loading: boolean;
  fetchData: (url: string, options?: FetchOptions) => Promise<void>;
}

function useFetchApi<T>(): FetchApiReturnType<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (
    url: string,
    options: FetchOptions = { method: "GET" },
  ) => {
    setLoading(true);

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = (await response.json()) as T;
      setData(result);
      setError(null);
    } catch (e) {
      setError(e.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetchData };
}

export default useFetchApi;
