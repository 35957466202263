export const share = (offerData) => {
  if (navigator.share) {
    try {
      navigator
        .share({
          title: "InKind",
          text: "Check out this amazing offer",
          url: offerData?.referral_link || "",
        })
        .then(() => {
          return;
        })
        .catch((err) => {
          // Handle the case where the user cancels sharing
          if (err.name === "AbortError") {
            return;
          } else {
            return;
          }
        });
    } catch (err) {
      console.log("Error initializing share", err);
    }
  } else {
    console.log("Web Share API not supported");
  }
};

export const copyLink = async (referral_link, setShowTooltip) => {
  try {
    if (referral_link) {
      // Copy from textRef if provided
      await navigator.clipboard.writeText(referral_link);
    } else {
      // Otherwise, copy the entire document body
      await navigator.clipboard.writeText(document.body.textContent);
    } // Optional feedback to the user
    await setShowTooltip(true);
    await setTimeout(() => {
      setShowTooltip(false);
    }, 2500);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
};

export function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );

  return isMobile;
}
