import axios from "@lib/axios";
import ForgotPassword from "../components/ForgotPassword";

export async function signIn(values) {
  return await axios.post("/api/v5/login", {
    user: {
      email: values.email,
      password: values.password,
    },
  });
}

export async function signUp(
  email,
  first_name,
  last_name,
  has_opted_out_email,
  password,
  phone_number,
  password_confirmation,
  nation_id,
  has_opted_out_sms = null,
) {
  return axios.post("/users.json", {
    user: {
      email,
      first_name,
      last_name,
      has_opted_out_email,
      password,
      phone_number,
      password_confirmation,
      nation_id,
      has_opted_out_sms,
    },
  });
}

export async function signOut() {
  return await axios.delete("/users/sign_out.json");
}

export async function getUserByToken(userToken) {
  const axiosHeaders = {
    headers: {
      Authorization: "Bearer ".concat(userToken),
    },
  };
  return await axios.get("/api/v5/current_user", axiosHeaders);
}

export async function getStripeSources(userToken) {
  const axiosHeaders = {
    headers: {
      Authorization: "Bearer ".concat(userToken),
    },
  };
  return await axios.get("/api/v3/current_user/stripe_sources", axiosHeaders);
}

export async function saveCard(cardToken, userToken) {
  const axiosHeaders = {
    headers: {
      Authorization: "Bearer ".concat(userToken),
    },
  };
  return await axios.post(
    "/api/v3/current_user/stripe_sources",
    {
      stripe_token: cardToken,
    },
    axiosHeaders,
  );
}

export async function changePassword(
  password,
  password_confirmation,
  reset_password_token,
) {
  return await axios.post("/api/v1/users/change_password", {
    user: {
      password,
      password_confirmation,
      reset_password_token,
    },
  });
}

export async function forgotPassword(email) {
  return await axios.post("/api/v1/users/forgot_password.json", {
    user: {
      email,
    },
  });
}
