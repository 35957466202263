import React from "react";
import ArrowLeft from "../../../assets/images/map_v2/arrow-left.svg";
import BackIcon from "../../../assets/images/map_v2/back-icon.svg";

const BackSection = ({ widget, handleExitMulti, handleBack, colorMode }) => {
  return (
    <div
      className={
        colorMode === "black"
          ? "back-section back-section-black"
          : "back-section"
      }
      style={{ paddingTop: widget === true ? "24px" : "16px" }}
      onClick={() => (!!handleExitMulti ? handleExitMulti() : handleBack())}
    >
      <img src={ArrowLeft} alt="Back" className="back-arrow" />
      <img src={BackIcon} alt="Back" className="back-icon" />
      <div className="back-text">Back</div>
    </div>
  );
};

export default BackSection;
