import React from "react";
import ActivityRow from "./ActivityRow.jsx";
import PreviewSend from "./PreviewSend.jsx";
import ImagesSolid from "../../../assets/images/icons/images-solid.svg";
import { Switch } from "@headlessui/react";
import { getDateTime, getOrdinal } from "../../helpers/formatDateTime";

const ActivityHistory = ({
  isPartnerPortal,
  id,
  chatMessages,
  logMessage,
  setLogMessage,
  sentState,
  setSentState,
  images,
  setImages,
  token,
  approvedState,
  setFinishedState,
  setAttachLength,
  isSending,
  setIsSending,
  deadlinePast,
  brandOwners,
  noReviewers,
  noApprovers,
  setApprovedState,
  handlePut,
  statusChat,
  setStatusChat,
  emailId,
  brandSlug,
  silentStatus,
  setSilentStatus,
}) => {
  const [chatMessagesUse, setChatMessagesUse] = React.useState(chatMessages);
  const [increment, setIncrement] = React.useState(0);
  const [attachPreview, setAttachPreview] = React.useState(
    !!isPartnerPortal ? false : true,
  );
  const [internalChecked, setInternalChecked] = React.useState(true);

  const handleNewMessage = (data) => {
    setIncrement(increment + 1);
    const newChatMessages = chatMessagesUse;
    newChatMessages.unshift(data.chat);
    if (data.chat.activity_type === "partner_approval" && !!setFinishedState) {
      setFinishedState(true);
    }
    if (!!images && images.length > 0) {
      setImages([]);
      setAttachLength(0);
    }
    if (!!setApprovedState && !!approvedState) {
      setApprovedState(false);
    }
    if (!!statusChat && !!setStatusChat) {
      setStatusChat(null);
    }
    setChatMessagesUse(newChatMessages);
    setIsSending(false);
  };

  const handleSend = (isInternal) => {
    setIsSending(true);
    let path = !isPartnerPortal
      ? `/admin/campaign_brands/${id}/chats`
      : window.location.origin.replace("admin.", "api.") +
        `/api/v5/campaign_brands/${id}/chats?token=${token}`;
    let data = {};
    data.is_internal = isInternal;
    if (!!approvedState) {
      data.silent_approval = silentStatus;
      data.activity_type = "partner_approval";
      !!emailId ? (data.message = "[ID: " + emailId + "]") : null;
    } else {
      data.message = !!emailId
        ? `${logMessage} [ID: ${!!emailId ? (data.message = emailId) : null}]`
        : logMessage;
      setLogMessage("");
      data.preview_sent = attachPreview;
      !!images && images.length > 0 ? (data.chat_images = images) : null;
    }
    const config = { method: "POST" };
    config.headers = !!isPartnerPortal
      ? new Headers({
          Authorization: token,
          "Content-Type": "application/json",
        })
      : new Headers({
          "Content-Type": "application/json",
        });
    config.body = JSON.stringify(data);
    fetch(path, config)
      .then((resp) => resp.json())
      .then((data) => {
        handleNewMessage(data);
      })
      .catch((err) => {
        setIsSending(false);
      });
  };

  React.useEffect(() => {
    if (sentState === true) {
      handleSend(false);
      setSentState(false);
      setSilentStatus(false);
    }
  }, [sentState]);

  React.useEffect(() => {
    !!statusChat ? handleNewMessage(statusChat) : null;
  }, [statusChat]);

  React.useEffect(() => {
    const historySection =
      document.getElementsByClassName("history-section")[0];
    historySection.scrollTop = !!isPartnerPortal
      ? 0
      : historySection.scrollHeight;
  }, [isSending]);

  return (
    <div>
      <h5>Activity</h5>
      <div className="activity-box">
        {!isPartnerPortal && (
          <PreviewSend
            isActivityLog={true}
            logMessage={logMessage}
            setLogMessage={setLogMessage}
            handleSend={handleSend}
            toggleValue={attachPreview}
            setToggleValue={setAttachPreview}
            isSending={isSending}
            showAutoApprove={deadlinePast ?? false}
            noReviewers={noReviewers}
            noApprovers={noApprovers}
            handlePut={handlePut}
            brandSlug={brandSlug}
          />
        )}
        <div className="history-section">
          <ul
            className={`activities-container ${
              !!isPartnerPortal ? "partner-row" : "admin-row"
            }`}
          >
            {!!chatMessagesUse &&
              chatMessagesUse.length > 0 &&
              chatMessagesUse.map((activity, index) => {
                return (
                  <ActivityRow
                    activity={activity}
                    index={index}
                    key={index}
                    getDateTime={getDateTime}
                    getOrdinal={getOrdinal}
                    brandOwners={brandOwners ?? []}
                  />
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ActivityHistory;
